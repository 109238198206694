import * as Yup from "yup";
import * as React from "react";
import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import TextFieldCustom from "../components/TextFieldCustom";
import TopBarComponent from "../components/TopBarComponent";
import { useFormik } from "formik";
import { useState } from "react";
import api from "./../conf/api";
import useSWR from "swr";

const SignUp = () => {
//   const { data, error, mutate } = useSWR(
//     "/56521b36-e1fc-4a31-a39f-bdfa1f4dafcf",
//     () =>
//       api.get("/56521b36-e1fc-4a31-a39f-bdfa1f4dafcf").then((res) => {
//         return res.data;
//       })
//   );

  const SignUpSchema = Yup.object().shape({
    email: Yup.string("Inserisci la tua email")
      .email("Mail non valida")
      .required("Mail obbligatoria"),

    password: Yup.string("Inserisci la password")
      .min(3, "Password troopo corta!")
      .max(12, "Password troppo lunga!")
      .required("Password obbligatoria")
      .matches(/[a-zA-Z]/, "Le password devono coincidere"),

    confirmPassword: Yup.string()
      .required("Conferma password obbligatoria")
      .oneOf([Yup.ref("password"), null], "Le password devono coincidere"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: SignUpSchema,
    onSubmit: (values) => {
      //   apiPost(values);
      // history.push("/users");
    },
  });
  return (
    <TopBarComponent>
      <div className="pt-14 h-full w-full flex flex-row justify-center items-center">
        <form
          component="form"
          noValidate
          autoComplete="off"
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-80"
          onSubmit={formik.handleSubmit}
        >
          <div className="grid grid-cols-1 text-center">
            <div className="p-2 m-2 flex items-center justify-center">
              <label className="text-4xl flex items-center text-center">
                Registrati
              </label>
            </div>
            <div className="m-2">
              <TextFieldCustom
                required
                id="email"
                label="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                className="w-60"
              />
            </div>

            <div className="m-2">
              <TextFieldCustom
                required
                id="password"
                label="password"
                type="password"
                name="password"
                autoComplete="current-password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                className="w-60"
              />
            </div>

            <div className="m-2">
              <TextFieldCustom
                required
                id="confirmPassword"
                label="Confirm password"
                type="text"
                name="confirmPassword"
                autoComplete="current-password"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                className="w-60"
              />
            </div>
            <div className="flex justify-center p-5">
              <Button variant="contained" type="submit" sx={{ color: "white" }}>
                Accedi
              </Button>
            </div>
            <div className="text-center text-xs">
              <p className="">Sei registrato?</p>
              <a
                className="hover:underline hover:text-sky-600 cursor-pointer"
                //   onClick={() => history.push("/sign-up")}
              >
                clicca qui
              </a>
            </div>
          </div>
        </form>
      </div>
    </TopBarComponent>
  );
};
export default SignUp;
