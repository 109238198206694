import CardComponent from "../components/CardComponent";
import TopBarComponent from "../components/TopBarComponent";
import useSWR from "swr";
import api from "../conf/api";
import Loading from "../components/Loading";
const AllEvents = () => {
    
  const { data, error, mutate } = useSWR("/events/show/{1}", (url) =>
    api.get(url).then((res) => {
      // console.log(res);
      //   return res.data.data.data;
    })
  );

  //   if (!data) {
  //     return <Loading />;
  //   }
  return (
    <TopBarComponent>
      <div className="pt-14">
        <div className="px-5 py-5">
          <CardComponent
            //   id={el.id}
            //   img={el.image_url}
            //   nameEvent={el.title}
            //   typeActivity={el.category.title}
            typeCard={"topCard"}
          />
        </div>
      </div>
    </TopBarComponent>
  );
};
export default AllEvents;
