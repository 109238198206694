import React, { useEffect, useState } from "react";
import TopBarComponent from "../components/TopBarComponent";
import moment from "moment";
import "moment/locale/it";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, DialogTitle, DialogContent } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CardComponent from "../components/CardComponent";
import LinearProgress from "@mui/material/LinearProgress";
import IconButton from "@mui/material/IconButton";
import api from "./../conf/api";
import useSWR from "swr";
import { mutate } from "swr";
import { linearProgressClasses } from "@mui/material/LinearProgress";
import Loading from "../components/Loading";
import { useGeolocated } from "react-geolocated";
import { connect } from "react-redux";
import _ from "lodash";
import AutocompleteApi from "../components/AutocompleteApi";
import { useNavigate } from "react-router";
import ErrorsComponent from "../components/ErrorComponent";
import moneta from "../img/moneta-educati.png";
import banner from "../img/banner-creativity.png";
import QrCodeIcon from "@mui/icons-material/QrCode";
import info from "../img/ico/icon_info.png";

const EventiIntorno = (props) => {
  // const [latitude, setLatitude] = useState(props.count.latitude);
  // const [longitude, setLongitude] = useState(props.count.longitude);
  // const [city, setCity] = useState("");

  // const handleConversion = () => {
  //   const convertedCity = getCity({ latitude, longitude });
  //   if (convertedCity) {
  //     setCity(convertedCity);
  //   } else {
  //     setCity("Nessuna città trovata per le coordinate fornite.");
  //   }
  // };

  // console.log(city);

  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 600);

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
      },
      userDecisionTimeout: 5000,
    });

  // console.log(isGeolocationAvailable, 'avaible'),
  // console.log(isGeolocationEnabled, 'enabled');

  console.log(coords);
  const navigate = useNavigate();
  const [allEvent, setAllEvent] = useState([]);

  useEffect(() => {
    if (coords) {
      props.dispatch.count.setLatitude(coords.latitude);
      props.dispatch.count.setLongitude(coords.longitude);
    }
  }, [coords, navigate]);

  api.setHeaders({
    Authorization: "Bearer " + props.count.token,
    latitude: props.count.latitude,
    longitude: props.count.longitude,
  });

  // api con apisauce + swr + mutate (new syntax)
  const { data, error } = useSWR("/events-featured", (url) =>
    api.get(url).then((res) => res.data.data.data)
  );

  const { data: messages, error: error_messages } = useSWR(
    props.count.token ? "/inbox-index" : null,
    (url) =>
      api.get(url).then((res) => {
        return res.data.data;
      })
  );

  const { data: me, error: errorMe } = useSWR("/me", (url) =>
    api.get(url).then((res) => res.data.data.user)
  );

  const { data: location, error: errorLocation } = useSWR("/location", (url) =>
    api.post(url).then((res) => res.data.data)
  );

  const { data: svolti, error: errorSvolti } = useSWR(
    props.count.token ? "/events-carried-out" : null,
    (url) => api.post(url, { page: 1 }).then((res) => res.data.data)
  );

  const { data: preferiti, error: errorPreferiti } = useSWR(
    props.count.token ? "/events-favorites" : null,
    (url) => api.get(url).then((res) => res.data.data.data)
  );

  const { data: readed, error: errorReaded } = useSWR(
    props.count.token ? "/inbox-not-readed" : null,
    (url) => api.get(url).then((res) => res)
  );

  const { data: qr, error: errorQr } = useSWR("/qr-code", (url) =>
    api.get(url).then((res) => {
      // console.log(res.data.data.data);
      setQrActivated(res.data.data.data.full_path);
      setQrTitle(res.data.data.data.title_event);
      return res.data.data.data;
    })
  );

  const [qrActivated, setQrActivated] = useState();
  const [qrTitle, setQrTitle] = useState();

  const [dataCalendar, setDataCalendar] = useState();
  var eta = null;
  var categoryResult = null;
  var cercaCatalogo = null;
  var ente = null;

  const [openQr, setOpenQr] = useState(false);
  const handleClickOpenQr = () => {
    setOpenQr(true);
  };
  const handleCloseQr = () => {
    setOpenQr(false);
  };
  const date = moment().format("DD/MM/YYYY");

  const SearchEvent = (val, name) => {
    api
      .post("/events-search", {
        date: date ? date : null,
        data:
          name === "data"
            ? moment(val["$d"]).format("YYYY/MM/DD")
            : dataCalendar
            ? moment(dataCalendar["$d"]).format("YYYY/MM/DD")
            : null,
        range_eta: name === "range_eta" ? val : eta ? eta : null,
        category_id:
          name === "category_id" ? val : categoryResult ? categoryResult : null,
        search_text:
          name === "search_text"
            ? val
            : cercaCatalogo
            ? cercaCatalogo.id
            : null,
        search_store: cercaCatalogo,
      })
      .then((res) => {
        // setEventResult(res.data.data.data);
        // setOpenEventiResult(true);
        navigate("/lista-eventi", { state: { prop: res.data.data.data } });
      })
      .catch((err) => console.log(err));
  };

  let today = moment();
  today.locale("it");

  const [value, setValue] = useState();

  const city = location && location.split(" ");

  if (error) return <ErrorsComponent />;
  if (props.count.token && !data) return <Loading />;
  // if (props.count.token && !svolti) return <Loading />;
  if (props.count.token && !location) return <Loading />;

  return (
    <TopBarComponent>
      <div>
        {/* button QR */}
        {props.count.token && (
          <div
            className="absolute z-50"
            style={{
              left: "80%",
              top: "77%",
            }}>
            <Button
              className="w-10 h-16 z-1"
              style={{
                backgroundColor: "#FE9100",
                borderRadius: "50%",
                position: "absolute",
              }}
              onClick={() => setOpenQr(true)}>
              <span className="text-white">
                <QrCodeIcon fontSize="large" />
              </span>
            </Button>

            {/* modale codice qr in home */}
            <Dialog
              onClose={handleCloseQr}
              aria-labelledby="customized-dialog-title"
              open={openQr}
              PaperProps={{
                style: {
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  margin: "auto",
                  maxHeight: "90vh",
                  borderRadius: "23px 23px 0 0",
                },
              }}
              sx={{
                // ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
                //   borderRadius: '6px 6px 0px',
                // },
                ".css-1bet5az-MuiTypography-root-MuiDialogTitle-root": {
                  backgroundColor: "#F18C08",
                },
                ".css-bdhsul-MuiTypography-root-MuiDialogTitle-root": {
                  padding: 1,
                },
              }}>
              <DialogTitle
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="bg-[#F18C08] text-white">
                <span
                  className="font-bold"
                  sx={{
                    flex: 1,
                    flex: 1,
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  Qr code generato
                </span>
                <IconButton
                  aria-label="Chiudi"
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: "white",
                  }}
                  onClick={handleCloseQr}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers>
                <p
                  className="text-center px-2 font-bold text-xl"
                  style={{ fontSize: "18px" }}>
                  {data && data.title}
                </p>

                {qrActivated && qrActivated ? (
                  <div className="px-10">
                    <div>
                      <p className="font-bold text-xl text-center">{qrTitle}</p>
                    </div>
                    <img
                      src={qrActivated && qrActivated}
                      alt=""
                      className="py-3 px-3"
                    />
                  </div>
                ) : (
                  <div className="py-10 flex justify-center">
                    <p>Il codice qr non è disponibile</p>
                  </div>
                )}

                <div className="text-center pb-12">
                  <p className="text-sm text-gray-500">
                    <span>
                      <img
                        src={info}
                        alt=""
                        className="px-1"
                        style={{ display: "inline" }}
                      />
                    </span>
                    Una volta usato il QR Code, il giusto numero di eDucati
                    verrà scalato dal tuo borsellino e l'evento verrà aggiunto
                    alla lista delle attività svolte
                  </p>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        )}

        <div
          className="pt-14"
          style={{
            backgroundColor: "#A1DADB",
            backgroundImage:
              "linear-gradient(180deg, rgba(161,218,219,1) 0%, rgba(255,255,255,1) 100%)",
          }}>
          {!isGeolocationEnabled && (
            <div className="flex justify-center">
              La geolocalizzazione è disabilitata
            </div>
          )}
          <div className="flex flex-row justify-between px-6 py-3">
            <div>
              <p className="font-bold text-2xl">Cerca</p>
            </div>
            {me && isGeolocationEnabled && (
              <div className="flex text-sm">
                <LocationOnIcon sx={{ fontSize: "20px" }} />
                <p>{me && me.city}</p>
              </div>
            )}
            {!me && location && (
              <div className="flex text-sm">
                <LocationOnIcon sx={{ fontSize: "20px" }} />
                <p>{location && city["5"]}</p>
              </div>
            )}
          </div>
          <div className="flex justify-center pt-3">
            <AutocompleteApi
              setAllEvent={setAllEvent}
              allEvent={allEvent}
              endPoint={"/events-title"}
              enti={false}
            />
          </div>

          <div
            className="pt-6 pb-4 text-sm capitalize flex justify-end "
            style={{ color: "#ff9100" }}>
            {/* <div
                className="flex flex-row itmes-center h-full shadow-lg p-3 pl-4 bg-white"
                style={{ borderBottomLeftRadius: "22px" }}
              >
                <div className="pr-2">
                  <CalendarTodayIcon />
                </div>
                <div className="self-center	">{today.format("D MMMM YYYY")}</div>
              </div> */}
          </div>
        </div>
      </div>
      {me && me.state == "MI" && (
        <div className="mx-6">
          <div className=" mt-2 text-md ">
            Attività svolte {_.size(svolti && svolti.data)}/8
          </div>
          <LinearProgress
            sx={{
              height: 6,
              borderRadius: 5,
              [`&.${linearProgressClasses.colorPrimary}`]: {
                backgroundColor: "#c5bfbf69",
              },
              [`& .${linearProgressClasses.bar}`]: {
                borderRadius: 5,
                backgroundColor: "#F18C08",
              },
            }}
            variant="determinate"
            value={
              _.size(svolti && svolti.data) * 10 == 80
                ? 100
                : _.size(svolti && svolti.data) * 10
            }
          />
        </div>
      )}
      <div className="w-full flex flex-col gap-2 mt-6">
        <div className="flex justify-between px-5 pt-5">
          <div className="text-2xl font-bold">Vicino a me</div>
          {/* <div>
            <p
              className="underline pt-2 font-bold text-lg"
              style={{ color: "#81CECF" }}>
              Vedi tutto
            </p>
          </div> */}
        </div>
        <div className="px-5">
          <p className="text-sm text-gray-500 ">disponibili oggi</p>
        </div>
        <div className="px-5">
          {data &&
            data.map((el, index) => {
              if (index == 0)
                return (
                  <div key={index}>
                    <CardComponent
                      mutate={mutate}
                      typeCard={"topCard"}
                      id={el.id}
                      res={el}
                      img={el.image_url}
                      nameEvent={el.title}
                      typeActivity={el.category.title}
                    />
                  </div>
                );
            })}
        </div>

        <div className="px-5 pt-5">
          <div>
            <p className="text-sm text-gray-500 py-2">Eventi intorno a me</p>
          </div>

          <div className="grid grid-cols-2 gap-4 py-2 md:justify-center">
            {data &&
              data.map((el, index) => {
                if (index !== 0)
                  return (
                    <React.Fragment key={index}>
                      <CardComponent
                        mutate={mutate}
                        typeCard={"menoRecenti"}
                        id={el.id}
                        res={el}
                        img={el.image_url}
                        nameEvent={el.title}
                        typeActivity={el.category.title}
                      />
                    </React.Fragment>
                  );
              })}
          </div>
        </div>

        {!props.count.token && <div className="pb-5"></div>}

        {
          // props.count.token &&
          // (
          //   <div className="px-5 pb-5">
          //     <div className="text-2xl font-bold pt-10 pb-7">Come Funziona</div>
          //     <Carousel
          //       autoPlay={false}
          //       sx={{ borderRadius: "25px", display: "relative", zIndex: "0" }}
          //     >
          //       <div>
          //         <img src={come_funziona2} alt="" />
          //       </div>
          //       {/* <div>
          //       <img src={contatti} alt="" />
          //     </div> */}
          //     </Carousel>
          //   </div>
          // )
        }

        {props.count.token && (
          <React.Fragment>
            <div className="text-2xl font-bold pt-5 pb-5 px-5">
              I miei eDucati
            </div>
            <div
              className="relative w-full h-full"
              style={{
                // backgroundImage: `url(${bannerEducati})`,
                backgroundColor: "#5BBFC1",
                backgroundSize: "cover",
                height: "12rem",
              }}>
              <span
                className="font-bold text-white absolute text-3xl relative"
                style={{ top: "4rem", left: "14rem" }}>
                {me && me.educati && me.educati}
              </span>
              <img
                src={moneta}
                className="font-bold text-white absolute text-3xl relative"
                style={{
                  top: "1.5rem",
                  left: "18.5rem",
                  width: "40px",
                  height: "40px",
                }}
              />
              <img
                src={banner}
                className="font-bold text-white absolute text-3xl relative"
                style={{
                  bottom: "3rem",
                  left: "1.2rem",
                  // width: "100px",
                  // height: "100px",
                }}
              />
              <div className="absolute" style={{ top: "63%", left: "53%" }}>
                <button
                  className="px-2 py-2"
                  variant="contained"
                  style={{
                    backgroundColor: "#F18C08",
                    borderRadius: "10px",
                  }}
                  onClick={() => SearchEvent()}>
                  <p className="text-white px-2 py-1 normal-case text-lg">
                    Eventi disponibili
                  </p>
                </button>
              </div>
              {/* <div
                className="absolute text-center"
                style={{ bottom: "4.5rem", left: "11rem" }}>
                <p className="text-white px-2 py-1 normal-case text-md">
                  Non perderti gli eventi nelle vicinanze
                </p>
              </div> */}
            </div>
            {svolti && !_.isEmpty(svolti.data) && (
              <div className="pr-2">
                <div className="text-2xl font-bold pt-10 px-5 pb-5">
                  <p>Attività svolte ({_.size(svolti && svolti.data)})</p>
                </div>
                <div className="flex flex-col justify-center max-w-sm overflow-x-auto py-2 pb-10 ">
                  <div className="px-5">
                    <div className="flex gap-3">
                      {svolti &&
                        svolti.data &&
                        svolti.data.map((el, index) => {
                          return (
                            <React.Fragment>
                              <CardComponent
                                key={index}
                                data_svolto={el.ticket_date}
                                mutate={mutate}
                                typeCard={"row"}
                                id={el.id}
                                res={el}
                                img={el.image_url}
                                nameEvent={el.title}
                                typeActivity={"svolte"}
                              />
                            </React.Fragment>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!_.isEmpty(preferiti) && !errorPreferiti && (
              <div
                style={{
                  backgroundColor: "#A1DADB",
                  backgroundImage:
                    "linear-gradient(0deg, rgba(161,218,219,1) 0%, rgba(255,255,255,1) 50%)",
                }}
                className="pr-2">
                <div className="text-2xl font-bold pt-10 px-5 pb-5">
                  <p>Aggiunti ai preferiti</p>
                </div>
                <div className="flex flex-col justify-center max-w-sm overflow-x-auto pt-2 pb-5">
                  <div className="px-5 w-full pb-2">
                    <div className="flex gap-6 w-full">
                      {preferiti &&
                        preferiti.map((el, index) => {
                          return (
                            <div key={index} style={{ width: "150.5px" }}>
                              <CardComponent
                                mutate={mutate}
                                typeCard={"row"}
                                id={el.id}
                                res={el}
                                img={el.image_url}
                                nameEvent={el.title}
                                typeActivity={el.category.title}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
      {/* <div
        className="h-20"
        style={{
          backgroundColor: "#A1DADB",
          backgroundImage:
            "linear-gradient(0deg, rgba(161,218,219,1) 0%, rgba(255,255,255,1) 100%)",
        }}
      >
      </div> */}
    </TopBarComponent>
  );
};
const mapState = (state) => ({
  count: state.count,
});
export default connect(mapState)(EventiIntorno);
