import React, { useEffect, useState } from "react";
import TopBarComponent from "../components/TopBarComponent";
// import { QrReader } from "react-qr-reader";
import { Button } from "@mui/material";
import App from "../App";

const Home = () => {

  // QRCODE react-qr-reader
  const [selected, setSelected] = useState("environment");
  const [startScan, setStartScan] = useState(false);
  const [loadingScan, setLoadingScan] = useState(false);
  const [data, setData] = useState("");

  const handleScan = (scanData) => {
    setLoadingScan(true);
    // console.log(`loaded data data`, scanData);
    if (scanData && scanData !== "") {
      // console.log(`loaded >>>`, scanData);
      setData(scanData);
      // setStartScan(false);
      setLoadingScan(false);
    }
    // setStartScan(false);
  };
  const handleError = (err) => {
    console.error(err);
  };
  // console.log(startScan);
  return (
    <TopBarComponent>
      <div className="pt-14">
        <p className="font-bold text-lg">
          Last Scan:
          {selected}
        </p>

        <Button
          variant="outlined"
          onClick={() => {
            setStartScan(!startScan);
            if (startScan == true) {
              window.location.reload();
            }
          }}
        >
          {startScan ? "Stop Scan" : "Start Scan"}
        </Button>
        {startScan && (
          <>
            <select onChange={(e) => setSelected(e.target.value)}>
              <option value={"environment"}>Back Camera</option>
              <option value={"user"}>Front Camera</option>
            </select>
            {/* <QrReader
              onResult={(result, error) => {
                if (!!result) {
                  setData(result?.text);
                  setStartScan(false);
                }

                // if (!!error) {
                //   console.info(error);
                // }
              }}
              facingMode={selected}
              onError={handleError}
              onScan={handleScan}
              style={{ width: "300px" }}
            /> */}
          </>
        )}
        {loadingScan && <p>Loading</p>}
        {data !== "" && <p className="font-bold text-xl">{data}</p>}
      </div>

      <div className="pt-10">
        
        Affido Culturale (AC) è un progetto selezionato dall'impresa sociale Con
        I Bambini, nell'ambito del Fondo di contrasto alla povertà educativa
        minorile. AC propone di mobilitare, contro la povertà educativa, delle
        “famiglie risorsa”, valorizzando l’esperienza dell’affido familiare, ma
        declinandola sullo specifico della fruizione di prodotti e servizi
        culturali. L’idea di fondo è molto semplice. Un genitore, che
        abitualmente porta i suoi figli al cinema, a teatro, al museo o in
        libreria, ci porta anche un bambino – eventualmente con un membro della
        famiglia di quest’ultimo – che in questi luoghi non ci entrerebbe per
        differenti cause. Si realizza così (a Napoli, Roma, Bari e Modena) un
        insieme di fruizioni culturali condivise, tramite le quali
        famiglie-risorsa e famiglie-destinatarie stringono un Patto Educativo:
        un sostegno complessivo multidimensionale promosso, garantito e
        monitorato dalla Scuola. Il progetto AC alimenta i Patti Educativi
        affidando alle famiglie-risorsa una dotazione di “e-ducati”, una moneta
        virtuale solidale, con cui pagare i biglietti di accesso a luoghi della
        cultura convenzionati ad hoc: cinema, teatri, musei etc., i quali così
        si aggiungono fattivamente alla comunità educante. Le transazioni in
        e-ducati viaggiano su una app appositamente realizzata, che facilita il
        monitoraggio delle attività, oltre che il fundraising di progetto.
        Affido Culturale (AC) è un progetto selezionato dall'impresa sociale Con
        I Bambini, nell'ambito del Fondo di contrasto alla povertà educativa
        minorile. AC propone di mobilitare, contro la povertà educativa, delle
        “famiglie risorsa”, valorizzando l’esperienza dell’affido familiare, ma
        declinandola sullo specifico della fruizione di prodotti e servizi
        culturali. L’idea di fondo è molto semplice. Un genitore, che
        abitualmente porta i suoi figli al cinema, a teatro, al museo o in
        libreria, ci porta anche un bambino – eventualmente con un membro della
        famiglia di quest’ultimo – che in questi luoghi non ci entrerebbe per
        differenti cause. Si realizza così (a Napoli, Roma, Bari e Modena) un
        insieme di fruizioni culturali condivise, tramite le quali
        famiglie-risorsa e famiglie-destinatarie stringono un Patto Educativo:
        un sostegno complessivo multidimensionale promosso, garantito e
        monitorato dalla Scuola. Il progetto AC alimenta i Patti Educativi
        affidando alle famiglie-risorsa una dotazione di “e-ducati”, una moneta
        virtuale solidale, con cui pagare i biglietti di accesso a luoghi della
        cultura convenzionati ad hoc: cinema, teatri, musei etc., i quali così
        si aggiungono fattivamente alla comunità educante. Le transazioni in
        e-ducati viaggiano su una app appositamente realizzata, che facilita il
        monitoraggio delle attività, oltre che il fundraising di progetto.
      </div>
    </TopBarComponent>
  );
};

export default Home;
