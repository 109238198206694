import React from "react";
import TopBarComponent from "../components/TopBarComponent";
import QuestionMarkRoundedIcon from "@mui/icons-material/QuestionMarkRounded";
import BoxMenu from "../components/BoxMenu";
import WebIcon from "@mui/icons-material/Web";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import LogoutIcon from "@mui/icons-material/Logout";
import CopyrightIcon from "@mui/icons-material/Copyright";
import tutorial_icon from "../img/ico/tutorial_icon.png";

import api from "./../conf/api";
import useSWR, { mutate } from "swr";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

const Altro = (props) => {
  api.setHeaders({
    Authorization: "Bearer " + props.count.token,
    latitude: props.count.latitude,
    longitude: props.count.longitude,
  });

  const navigate = useNavigate();

  const notificationIconStyle = {
    position: "relative",
  };

  const dotStyle = {
    position: "absolute",
    top: "0",
    right: "0",
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    backgroundColor: "red",
  };

  return (
    <TopBarComponent>
      <div
        className="pt-14 pb-36 px-5"
        style={{
          backgroundColor: "#A1DADB",
          backgroundImage:
            "linear-gradient(180deg, rgba(161,218,219,1) 0%, rgba(255,255,255,1) 100%)",
        }}
      >
        <BoxMenu
          onClickCustom={() => navigate("/affido-culturale")}
          title={"Che cos'è AC"}
          icon={
            <Button sx={{ padding: "0px", minWidth: "0px" }}>
              <QuestionMarkRoundedIcon sx={{ color: "#F18C09" }} />
            </Button>
          }
        />
        <BoxMenu
          title={"Tutorial"}
          onClickCustom={() => navigate("/tutorial")}
          icon={
            <Button sx={{ padding: "0px", minWidth: "0px" }}>
              <img src={tutorial_icon} sx={{ color: "#F18C09" }} />
            </Button>
          }
        />
        <a
          sx={{ padding: "0px", minWidth: "0px" }}
          href="https://percorsiconibambini.it/ac-affidoculturale/autore/associazionepiomontedellamisericordia/"
        >
          <BoxMenu
            title={"Blog"}
            icon={<WebIcon sx={{ color: "#F18C09" }} />}
          />
        </a>
        <BoxMenu
          title={"Credits"}
          onClickCustom={() => navigate("/credits")}
          icon={
            <Button sx={{ padding: "0px", minWidth: "0px" }}>
              <CopyrightIcon sx={{ color: "#F18C09" }} />
            </Button>
          }
        />
        {props.count.token ? (
          <>
            <BoxMenu
              onClickCustom={() => {
                navigate("/notifiche");
              }}
              title={"Notifiche"}
              icon={
                <Button sx={{ padding: "0px", minWidth: "0px" }}>
                  <NotificationsActiveIcon
                    sx={{
                      color: "#F18C09",
                    }}
                  />

                  {props.count.push == 1 && (
                    <div
                      className="absolute"
                      style={{
                        bottom: "40%",
                        left: "-60%",
                        width: "7px",
                        height: "7px",
                        backgroundColor: "#F18C23",
                        // #F18C23
                        borderRadius: "50%",
                      }}
                    />
                  )}
                </Button>
              }
            />
            {/* <BoxMenu
              title={"Impostazioni"}
              icon={
                <Button sx={{ padding: "0px", minWidth: "0px" }}>
                  <SettingsIcon sx={{ color: "#F18C09" }} />
                </Button>
              }
            /> */}
            <BoxMenu
              onClickCustom={() => {
                props.dispatch.count.addToken(null);
                mutate("/events-featured");
                navigate("/");
                // window.location.reload();
              }}
              title={"Logout"}
              icon={
                <Button
                  sx={{ padding: "0px", minWidth: "0px" }}
                  onClick={() => {
                    props.dispatch.count.addToken("");
                    navigate("/");
                  }}
                >
                  <LogoutIcon sx={{ color: "#F18C09" }} />
                </Button>
              }
            />
          </>
        ) : (
          <BoxMenu
            onClickCustom={() => navigate("/log-in")}
            title={"Login"}
            icon={
              <Button sx={{ padding: "0px", minWidth: "0px" }}>
                <LogoutIcon sx={{ color: "#F18C09" }} />
              </Button>
            }
          />
        )}
      </div>
    </TopBarComponent>
  );
};
const mapState = (state) => ({
  count: state.count,
});
export default connect(mapState)(Altro);
