import React, { useState } from "react";
import { TextField, Autocomplete, IconButton } from "@mui/material";
import _ from "lodash";
import api from "../conf/api";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { connect } from "react-redux";

const AutocompleteApi = ({
  setAllEvent,
  allEvent,
  endPoint,
  setCercaCatalogo,
  children,
  enti,
  openEnte,
  setEnte,
  ente,
  dispatch,
  count,
}) => {
  const navigate = useNavigate();
  const [cerca, setCerca] = useState();
  const [valoreEnte, setValoreEnte] = useState("");

  return (
    <div className="flex flex-row w-full items-center gap-2 mx-2">
      <Autocomplete
        value={cerca}
        fullWidth
        autoComplete={true}
        onChange={(event, newValue) => {
          setCerca(newValue);
          setCercaCatalogo && setCercaCatalogo(newValue);
        }}
        onInputChange={(event, value) => {
          if (event !== null) {
            setValoreEnte(value);
            setEnte && setEnte(value);
            value.length > 3 &&
              api
                .post(endPoint, {
                  title: value,
                })
                .then((res) => {
                  setAllEvent(
                    endPoint == "/search-enti"
                      ? res.data.data.data
                      : res.data.data
                  );
                })
                .catch((err) => console.log(err));
          }
        }}
        getOptionLabel={(option) =>
          endPoint == "/search-enti" ? option.business_name : option.title
        }
        options={!_.isEmpty(allEvent) ? allEvent : []}
        renderInput={(params) => (
          <TextField
            sx={{
              borderRadius: "30px",
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: 20,
              },
              "& .MuiInputBase-root": {
                border: "none",
                boxShadow: "none",
              },
            }}
            placeholder={ente ? "Cerca nome ente…" : "Cerca nome evento…"}
            className="bg-white shadow-xl"
            {...params}
          />
        )}
      />
      {!openEnte && (
        <>
          {setCercaCatalogo ? (
            children
          ) : (
            <Box
              sx={{
                "& .MuiButtonBase-root-MuiIconButton-root:hover": {
                  backgroundColor: "white",
                },
              }}>
              <Paper
                elevation={6}
                sx={{
                  borderRadius: "50%",
                  backgroundColor: "white",
                  padding: 1.5,
                  "& .MuiButtonBase-root-MuiIconButton-root:hover": {
                    backgroundColor: "white",
                  },
                }}
                onClick={() => {
                  dispatch.count.setEnte(valoreEnte);
                  cerca && endPoint == "/search-enti"
                    ? navigate("/lista-eventi", {
                        state: {
                          data: null,
                          range_eta: null,
                          category_id: null,
                          search_store: valoreEnte,
                          search_text: null,
                        },
                      })
                    : navigate("/event-detail/" + cerca.id);
                }}>
                <SearchIcon sx={{ color: "black" }} />
              </Paper>
            </Box>
          )}
        </>
      )}
    </div>
  );
};
const mapState = (state) => ({
  count: state.count,
});
export default connect(mapState)(AutocompleteApi);
