import { IconButton } from "@mui/material";
import TopBarComponent from "../../components/TopBarComponent";
import hpoLogo from "../../img/logo_hpo.png";
import { useNavigate } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Credits = () => {
  const navigate = useNavigate();

  return (
    <TopBarComponent>
      <div className="w-full mt-8 pl-4">
        <IconButton
          className="mb-4 border border-black p-10"
          onClick={() => navigate("/altro")}
          sx={{ width: "20px", borderRadius: "50px" }}
        >
          <div className="flex flex-row items-center text-sm bg-white px-4 py-1 shadow-md rounded-lg ml-9">
            <ArrowBackIcon />
          </div>
        </IconButton>
      </div>
      <div className="pt-4 px-5 pb-7">
        <div className="pb-3">
          <p className="text-3xl font-bold">Credits</p>
        </div>
        <div>
          <p className="text-sm text-gray-500">
            Affido Culturale è un progetto selezionato dall'impresa sociale "Con
            I Bambini" nell'ambito del Fondo di contrasto alla povertà educativa
            minorile, che ha realizzato questa app sviluppata dal team di
          </p>
          <div className="px-14 py-4 shadow-xl rounded-xl ">
            <img src={hpoLogo} alt="" />
          </div>
        </div>
        <div className="pt-7 pb-3">
          <p className="text-xl font-bold text-gray-500">Il Team</p>
        </div>
        <div className="pb-2">
          <p className="text-sm font-bold text-gray-500">
            Amministratore e Team Manager
          </p>
        </div>
        <div className="text-gray-500">
          <p>Angelo Romano</p>
        </div>
        <div className="pb-2 pt-3">
          <p className="text-sm font-bold text-gray-500">Developers</p>
        </div>
        <div className="text-gray-500">
          <p>Sabatino Siciliano e Vittorio Di Gennaro</p>
        </div>
        <div className="pb-2 pt-3">
          <p className="text-sm font-bold text-gray-500">Ui Artist</p>
        </div>
        <div className="text-gray-500">
          <p>Manuela Labriola</p>
        </div>
      </div>
    </TopBarComponent>
  );
};
export default Credits;
