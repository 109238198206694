import * as React from "react";
import { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardMedia,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import InfoIcon from "@mui/icons-material/Info";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { useNavigate, useParams } from "react-router";
import useSWR from "swr";
import api from "../conf/api";
import { connect } from "react-redux";
import ErrorsComponent from "../components/ErrorComponent";
import Loading from "../components/Loading";
import moment from "moment";
import moneta_educati from "../img/ico/moneta_educati.png";
import moneta_euro from "../img/ico/moneta_euro.png";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import info from "../img/ico/icon_info.png";
import TopBarComponent from "../components/TopBarComponent";
import { lowerCase } from "lodash";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import info_ico_orange from "../img/ico/Icon_info_1.png";
import _ from "lodash";
import Box from "@mui/material/Box";
import "dayjs/locale/it";
import dayjs from "dayjs";

const EventDetail = (props) => {
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  api.setHeaders({
    Authorization: "Bearer " + props.count.token,
    latitude: props.count.latitude,
    longitude: props.count.longitude,
  });

  const [indexRow, setIndexRow] = useState();

  const { data, error, mutate } = useSWR("/events/show/" + id, (url) =>
    api.get(url).then((res) => {
      setStartDate(new Date(res.data.data.date_start));
      setEndDate(new Date(res.data.data.date_end));

      return res.data.data;
    })
  );
  const [dateApi, setDateApi] = useState(null);

  const [clickedDay, setclickedDay] = useState(null);
  const [startHour, setStartHour] = useState();
  const [endHour, setEndHour] = useState();

  const { data: qr, error: errorQr } = useSWR(
    props.count.token && id && dateApi && startHour ? `/events-check` : null,
    (url) =>
      api
        .post(url, {
          id: id,
          day: dateApi,
          hour: startHour,
        })
        .then((res) => res.data.data)
  );

  const [hourClicked, setHourClicked] = useState(false);

  const navigate = useNavigate();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [openInfo, setOpenInfo] = useState(false);
  const handleClickOpenInfo = () => {
    setOpenInfo(true);
  };

  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  const [modalLogIn, setModalLogIn] = useState(false);
  const handleOpenLogIn = () => {
    setModalLogIn(true);
  };
  const handleCloseLogIn = () => {
    setModalLogIn(false);
  };

  const [open, setOpen] = useState(false);

  // modale per scegliere la data dell'evento all'onClick sul giorno del calendario
  const [openDate, setOpenDate] = useState(false);
  const modalOpenDate = () => {
    setOpenDate(true);
  };
  const modalCloseDate = () => {
    setOpenDate(false);
  };

  const [dataDay, setDataDay] = useState(null);

  const handleDateChange = (date) => {
    moment.locale("en");
    setDateApi(moment(date.$d).add(1, "days"));
    setclickedDay(date);
    setDataDay(data.timesheet_new[lowerCase(moment(date.$d).format("dddd"))]);
    moment.locale("it");
  };

  const [openQr, setOpenQr] = useState(false);
  const [cost_intero, setCost_intero] = useState(0);
  const [cost_ridotto, setCost_ridotto] = useState(0);
  const handleClickOpenQr = () => {
    setOpenQr(true);
  };
  const handleCloseQr = () => {
    setOpenQr(false);
  };
  // const [timeExiste, setTimeExiste] = useState();
  var timeExiste = "";

  const moment = require("moment");
  moment.locale("it");

  const today = moment();

  if (error) return <ErrorsComponent />;
  if (!data) return <Loading />;
  return (
    <TopBarComponent>
      <Dialog
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: {
            borderRadius: 23,
            paddingBottom: 10,
            paddingTop: 10,
            position: "absolute",
            left: 0,
            right: 0,
            margin: "auto",
            maxHeight: "80vh",
            width: "90%",
          },
        }}
        sx={{
          ".MuiDialogContent-root": {
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
            padding: 0,
          },
          ".css-1cafy48-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition":
            {
              minHeight: 194,
            },
        }}>
        <DialogTitle className="text-center flex justify-between">
          <p className="font-bold pt-1 pl-14" style={{ fontSize: "16px" }}>
            Seleziona una data
          </p>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <LocalizationProvider adapterLocale={"it"} dateAdapter={AdapterDayjs}>
            <DateCalendar
              defaultValue={today}
              dayOfWeekFormatter={(day, date) => {
                if (day == "Su") return "Dom";
                if (day == "Mo") return "Lun";
                if (day == "Tu") return "Mar";
                if (day == "We") return "Mer";
                if (day == "Th") return "Gio";
                if (day == "Fr") return "Ven";
                if (day == "Sa") return "Sab";
              }}
              minDate={dayjs(data.date_start, "YYYY-MM-DD")}
              maxDate={dayjs(data.date_end, "YYYY-MM-DD")}
              renderInput={(props) => <TextField {...props} />}
              value={clickedDay}
              onChange={handleDateChange}
              sx={{
                "& .MuiPickersCalendarHeader-label": {
                  color: "#6EC5C7",
                },
                ".MuiTypography-root": {
                  color: "#F29A26",
                  fontWeight: "bold",
                  fontSize: 13,
                },
                "& .MuiPickersDay-root.Mui-selected": {
                  color: "white",
                  backgroundColor: "#F29A26",
                },
                "& .MuiPickersDay-root.Mui-selected:hover": {
                  color: "white",
                  backgroundColor: "#F29A26",
                  fontWeight: "bold",
                },
                "& .MuiPickersDay-root:not(.Mui-selected)": {
                  border: "0px transparent",
                  fontSize: 14,
                },
              }}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions className="mr-5">
          <Button
            variant="contained"
            autoFocus
            onClick={() => {
              setOpen(false);
              modalOpenDate();
            }}>
            <p className="text-white normal-case">Salva</p>
          </Button>
        </DialogActions>
      </Dialog>
      <div
        className="pt-14 pb-10 px-4"
        style={{
          backgroundColor: "#A1DADB",
          backgroundImage:
            "linear-gradient(180deg, rgba(161,218,219,1) 0%, rgba(255,255,255,1) 100%)",
        }}>
        <div className="z-index z-10 pb-5">
          <Box
            sx={{
              "& .MuiButtonBase-root": {
                backgroundColor: "white",
              },
              "& .css-1ont1xo-MuiButtonBase-root-MuiButton-root:hover": {
                backgroundColor: "white",
              },
            }}>
            <Button
              onClick={() => navigate(-1)}
              className="shadow-md"
              sx={{
                borderRadius: "10px",
                backgroundColor: "white",
                ".css-1g8co14-MuiButtonBase-root-MuiButton-root": {
                  minWidth: 2,
                },
              }}
              // onClick={handleCloseLocalization}
            >
              <ArrowBackIcon className="text-black" />
            </Button>
          </Box>
        </div>
        <Card style={{ borderRadius: "25px", minWidth: "161.5px" }}>
          {/* <CardActionArea> */}
          <div>
            <CardMedia
              component="img"
              image={data && data.image_url}
              className="h-28"
            />
            <div
              style={{
                backgroundColor: `${data && data.color_cate}`,
                borderRadius: "0px 6px 0px 0px",
              }}
              className={`relative bottom-6 w-28 h-6 pl-2 flex flex-row items-center text-white`}>
              {data && (
                <img
                  className="object-contain h-5 w-5"
                  alt={"img"}
                  src={data.category_url}
                  style={{ paddingBottom: "2px" }}
                />
              )}
              <div className="uppercase w-full text-xs text-dark pl-2 max-w-xs ">
                {data && data.cat_name}
              </div>
            </div>

            <div>
              <div
                style={{
                  left: "82%",
                  bottom: "120px",
                }}
                className="relative">
                <button
                  className="px-3 py-1 shadow-lg rounded-lg bg-white"
                  onClick={handleClickOpenInfo}>
                  <InfoIcon
                    sx={{ width: "20px", height: "20px" }}
                    className="text-gray-400"
                  />
                  {/* <img src={} alt="" /> */}
                </button>
              </div>

              <Dialog
                PaperProps={{
                  style: {
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    margin: "auto",
                    maxHeight: "90vh",
                    borderRadius: "25px 25px 0 0",
                  },
                }}
                onClose={handleCloseInfo}
                aria-labelledby="customized-dialog-title"
                open={openInfo}
                sx={{
                  ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
                    borderRadius: "20px 20px 0px 0px",
                  },
                  ".css-1bet5az-MuiTypography-root-MuiDialogTitle-root": {
                    backgroundColor: "#F18C08",
                  },
                  ".css-bdhsul-MuiTypography-root-MuiDialogTitle-root": {
                    padding: 1,
                  },
                }}>
                <DialogTitle
                  id="customized-dialog-title"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="bg-[#F18C08] text-white">
                  <span
                    sx={{
                      flex: 1,
                      flex: 1,
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                    className="font-bold text-lg">
                    Info evento
                  </span>
                  <IconButton
                    aria-label="Chiudi"
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: "white",
                    }}
                    onClick={handleCloseInfo}>
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>

                <DialogContent dividers>
                  <Typography gutterBottom className="pb-3">
                    {data.short_description}
                  </Typography>
                  <img
                    src={data && data.image_url}
                    alt={"img"}
                    loading="lazy"
                  />
                  <Typography gutterBottom className="pt-5 pb-12">
                    {data.description}
                  </Typography>
                  {/* <div>maps</div> */}
                </DialogContent>
                {/* <DialogActions>
                  <Button autoFocus onClick={handleCloseInfo}>
                    Chiudi
                  </Button>
                </DialogActions> */}
              </Dialog>
            </div>
          </div>
          {/* </CardActionArea> */}
          {/* <CardActionArea> */}
          <div className="flex flex-row pb-5 px-5">
            <div>
              <p className="text-lg font-bold pl-1 pr-5">{data.title}</p>
            </div>
            <div className="pr-3">
              <DialogContent
                sx={{
                  ".css-1utwhs6-MuiButtonBase-root-MuiButton-root": {
                    paddingLeft: 1,
                    paddingRight: 1,
                    minWidth: 0,
                    backgroundColor: "#F18C08",
                  },
                  ".css-1utwhs6-MuiButtonBase-root-MuiButton-root.Mui-disabled":
                    {
                      backgroundColor: "rgba(0, 0, 0, 0.12)",
                    },
                  padding: "0px",
                  // ".css-6bawsm-MuiDialogContent-root":{padding: 0}
                }}>
                <Button
                  disabled={!startHour ? true : false}
                  onClick={
                    props.count.token ? handleClickOpenQr : handleOpenLogIn
                  }
                  className="shadow-md rounded-md w-24 py-2">
                  <span className="flex flex-row items-center normal-case text-white">
                    <QrCode2Icon sx={{ fontSize: "25px" }} />
                    <div className="mt-1">Genera</div>
                  </span>
                </Button>
              </DialogContent>

              <Dialog
                onClose={handleCloseQr}
                aria-labelledby="customized-dialog-title"
                open={openQr}
                PaperProps={{
                  style: {
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    margin: "auto",
                    maxHeight: "90vh",
                    borderRadius: "23px 23px 0 0",
                  },
                }}
                sx={{
                  // ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
                  //   borderRadius: '6px 6px 0px',
                  // },
                  ".css-1bet5az-MuiTypography-root-MuiDialogTitle-root": {
                    backgroundColor: "#F18C08",
                  },
                  ".css-bdhsul-MuiTypography-root-MuiDialogTitle-root": {
                    padding: 1,
                  },
                }}>
                <DialogTitle
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="bg-[#F18C08] text-white">
                  <span
                    sx={{
                      flex: 1,
                      flex: 1,
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                    }}>
                    Qr code
                  </span>
                  <IconButton
                    aria-label="Chiudi"
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: "white",
                    }}
                    onClick={handleCloseQr}>
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                  <p
                    className="text-center px-2 font-bold text-xl"
                    style={{ fontSize: "18px" }}>
                    {data.title}
                  </p>
                  {qr && qr.image ? (
                    <div className="px-10 py-5">
                      <img
                        src={qr.image && qr.image}
                        alt=""
                        className="pt-3 pb-1 px-3"
                      />
                    </div>
                  ) : (
                    <div className="py-10 flex justify-center">
                      <p>Il codice qr non è disponibile</p>
                    </div>
                  )}

                  <div className="text-center">
                    <p className="text-sm text-gray-500">
                      <span>
                        <img
                          src={info}
                          alt=""
                          className="px-1"
                          style={{ display: "inline" }}
                        />
                      </span>
                      Una volta usato il QR Code, il giusto numero di eDucati
                      verrà scalato dal tuo borsellino e l'evento verrà aggiunto
                      alla lista delle attività svolte
                    </p>

                    <p className="text-sm text-[#F18C08] pt-5">
                      Puoi visualizzare questo QR Code nella home finchè sarà
                      valido
                    </p>

                    {/* <Button
                      variant="contained"
                      sx={{ marginTop: "20px", marginBottom: "10px" }}
                    >
                      <span className="text-white font-sm ">Visualizza</span>
                    </Button> */}
                  </div>
                </DialogContent>
                {/* <DialogActions>
                  <Button autoFocus onClick={handleCloseQr}>
                    Chiudi
                  </Button>
                </DialogActions> */}
              </Dialog>

              {/* modal login  */}
              <Dialog
                onClose={handleCloseLogIn}
                aria-labelledby="customized-dialog-title"
                open={modalLogIn}
                sx={{
                  ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
                    borderRadius: 6,
                  },
                  ".css-1bet5az-MuiTypography-root-MuiDialogTitle-root": {
                    backgroundColor: "#F18C08",
                  },
                  ".css-bdhsul-MuiTypography-root-MuiDialogTitle-root": {
                    padding: 1,
                  },
                }}>
                <DialogTitle
                  id="customized-dialog-title"
                  onClose={handleCloseLogIn}
                  className="bg-[#F18C08] text-white">
                  <div className="text-center font-bold text-sm">
                    Non hai fatto il login
                  </div>
                </DialogTitle>
                <DialogContent dividers>
                  <div>
                    <p>
                      Se non hai le <b>credenziali</b>, contatta{" "}
                      <b>l'assistente </b>che vi sta seguendo. In caso contrario
                      puio fare <b>il log</b> in nella sezione profilo
                    </p>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={() => navigate("/log-in")}>
                    Accedi
                  </Button>
                  <Button autoFocus onClick={handleCloseLogIn}>
                    Chiudi
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
          <div>
            <p className="px-6 pb-5">{data.short_description}</p>
          </div>

          <div className="px-6 pb-3">
            <a
              href="https://www.google.it/maps/preview"
              className="font-bold text-sm underline text-[#5BBFC1]">
              Apri mappa
            </a>
          </div>

          {data && (
            <div className="flex flex-row px-5">
              <div>
                <LocationOnIcon sx={{ fontSize: "22px" }} />
                <span style={{ fontSize: "14px" }}>
                  {data.address_complete}
                </span>
              </div>
              <div className="pl-4">
                {/* <span
                style={{ fontSize: "13px" }}
                className="font-bold underline text-[#F18C08]"
              >
                Vedi mappa
              </span> */}
              </div>
            </div>
          )}
          {data && (
            <div className="flex flex-row px-5 gap-3 pt-5 pb-3">
              <div>
                <CalendarMonthIcon sx={{ fontSize: "22px" }} />
                <span
                  style={{ fontSize: "14px" }}
                  className="inline-block align-middle ml-2">
                  {data.date_start_custom.replace(/-/g, "/")} a{" "}
                  {data.date_end_custom.replace(/-/g, "/")}
                </span>
              </div>
            </div>
          )}
          {!startHour && (
            <div className="px-5 pt-3">
              {/* old button  seleziona data*/}
              {/* <button
                onClick={() => setOpen(true)}
                fullWidth
                variant="outlined"
                sx={{
                  "&:active": {
                    backgroundColor: "#F18C09",
                    color: "white",
                  },
                  // padding: "4px",
                }}
                className="border-2 text-[#F18C09] border-[#F18C09] rounded-md px-14"
              >
                <span className="text-xs normal-case font-bold">
                  Seleziona data
                </span>
              </button> */}

              <Button
                fullWidth
                onClick={() => setOpen(true)}
                variant="outlined"
                sx={{
                  // width: "300px",
                  border: 2,
                  color: "#F18C08",
                  "&:active": {
                    backgroundColor: "#F18C09",
                    color: "white",
                  },
                }}>
                <span className="normal-case">Seleziona data</span>
              </Button>
            </div>
          )}
          {clickedDay && startHour && (
            <div className="px-5">
              <div className="pb-4">
                <p className="font-bold">Data e ora selezionata</p>
                <div
                  onClick={() => {
                    setclickedDay(null);
                    setStartHour(null);
                    setOpen(true);
                    setIndexRow(null);
                  }}
                  className="shadow-lg flex flex-row justify-between px-3 text-sm rounded-lg"
                  style={{ color: "#F18C09" }}>
                  <div className="py-2">
                    <div className="font-bold ">
                      {clickedDay &&
                        clickedDay.$d &&
                        moment(clickedDay.$d)
                          .locale("it")
                          .format("DD MMMM YYYY")}
                    </div>
                    <div>
                      {startHour} - {endHour}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <EditCalendarIcon />
                  </div>
                </div>
              </div>
            </div>
          )}

          {startHour && (
            <>
              <div className="px-5 display">
                <p className="font-bold">Costo</p>
              </div>
              <div className="px-5">
                <img
                  src={moneta_educati}
                  className="mr-2"
                  style={{ display: "inline" }}
                />
                <span className="text-sm">{cost_intero}</span>
              </div>

              <div
                className="px-5"
                style={{
                  paddingLeft: "22px",
                }}>
                <img
                  src={moneta_euro}
                  className="mr-2"
                  style={{ display: "inline" }}
                />
                <span className="text-sm pl-1">{cost_ridotto}</span>
              </div>
            </>
          )}

          <div className="flex justify-center pt-3 px-5">
            <Button
              fullWidth
              disabled={!startHour ? true : false}
              onClick={props.count.token ? handleClickOpenQr : handleOpenLogIn}
              variant="contained"
              sx={{ backgroundColor: "#F18C08" }}>
              <span className="text-white normal-case">Genera QR code</span>
            </Button>
          </div>
          <div
            className="pb-5 px-16 text-center text-sm pt-4"
            style={{ color: "#F4A04B" }}>
            {!startHour && (
              <>
                <img
                  src={info_ico_orange}
                  alt=""
                  style={{ display: "inline", width: "20px", padding: "3px" }}
                  className=""
                />

                <span>Per generare il QR code seleziona data evento</span>
              </>
            )}
          </div>
          {/* </CardActionArea> */}
        </Card>
      </div>

      <div>
        <Dialog
          open={openDate}
          onClose={() => modalCloseDate()}
          fullWidth
          PaperProps={{
            style: {
              margin: "auto",
              maxHeight: "80vh",
              width: "100%",
              borderRadius: "23px",
            },
          }}>
          <DialogTitle>
            <div className="flex flex-row justify-between items-center font-bold text-base pl-20">
              <div>Seleziona un orario</div>
              <IconButton onClick={() => modalCloseDate()}>
                <CloseIcon />
              </IconButton>
            </div>
            {/* <Button
              className="flex align-middle"
              style={{ color: "black" }}
              onClick={() => modalCloseDate()}>
              <CloseIcon className="mt-1" />
              <p className="normal-case mt-1" style={{ fontSize: "16px" }}>
                Seleziona un orario
              </p>
            </Button> */}
          </DialogTitle>
          <DialogContent className="grid grid-cols-2 h-full w-full justify-center gap-2 mb-3">
            {_.isEmpty(dataDay) ? (
              <div className="text-black text-center w-full col-span-2">
                Orario non disponibile
              </div>
            ) : (
              dataDay &&
              dataDay.map((el, index) => {
                timeExiste = el.time_start;
                return (
                  <React.Fragment key={index}>
                    <Button
                      onClick={(e) => {
                        setIndexRow(index);
                        setStartHour(el.time_start.slice(0, -3));
                        setEndHour(el.time_end.slice(0, -3));
                        setCost_intero(el.cost);
                        setCost_ridotto(el.cost_euro);
                        setHourClicked(true);
                      }}
                      // variant={hourClicked == true ? "contained" : "outlined"}
                      variant="outlined"
                      style={{
                        // margin: 10,
                        color: "#5BBFC1",
                        borderColor: "#5BBFC1",
                        backgroundColor:
                          indexRow === index ? "#5BBFC1" : "white",
                        color: indexRow === index ? "white" : "#5BBFC1",
                        height: "30px",
                      }}>
                      {el.time_start.slice(0, -3)} -{" "}
                      {timeExiste && el.time_end.slice(0, -3)}
                    </Button>
                  </React.Fragment>
                );
              })
            )}
          </DialogContent>
          <DialogActions
            className="mr-5 flex flex-row justify-center"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              ".css-16w4spq-MuiButtonBase-root-MuiButton-root:hover": {
                backgroundColor: "#5BBFC1",
              },
              center: {
                justifyContent: "center",
              },
            }}>
            {timeExiste ? (
              <Button
                variant="contained"
                sx={{ color: "white", backgroundColor: "#5bbfc1" }}
                onClick={() => modalCloseDate()}>
                <span className="normal-case">Salva</span>
              </Button>
            ) : (
              <Button
                variant="contained"
                sx={{ color: "white", backgroundColor: "#5bbfc1" }}
                onClick={() => {
                  modalCloseDate();
                  setOpen(true);
                }}>
                <span className="normal-case">Torna in dietro</span>
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    </TopBarComponent>
  );
};
const mapState = (state) => ({
  count: state.count,
});
export default connect(mapState)(EventDetail);
