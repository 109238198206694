import * as React from "react";
import { BrowserRouter, Routes, Route, Link, redirect } from "react-router-dom";
import Home from "./view/Home";
import { theme } from "./conf/theme";
import { ThemeProvider } from "@mui/material";
import EventiIntorno from "./view/EventiIntorno";
import Cerca from "./view/Cerca";
import Altro from "./view/Altro";
import Preferiti from "./view/Preferiti";
import Profilo from "./view/Profilo";
import Category from "./view/Category";
import EventDetail from "./view/EventDetail";
import LogIn from "./view/LogIn";
import SignUp from "./view/SignUp";

import { Provider } from "react-redux";
import createPersistPlugin, { getPersistor } from "@rematch/persist";
import { PersistGate } from "redux-persist/lib/integration/react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { init } from "@rematch/core";
import * as count from "./LocaStorage/models";
import AffidoCulturale from "./view/Altro/AffidoCulturale";
import Credits from "./view/Altro/Credits";
import AllEvents from "./view/AllEvents";
import Notification from "./view/Altro/Notification";
import EventsListFiltred from "./view/EventsListFiltred";
import { useEffect, useState } from "react";
import Tutorial from "./view/Altro/Tutorial";

function App() {
  const persistPlugin = createPersistPlugin({
    key: "affidoCulturale",
    storage: AsyncStorage,
  });
  const store = init({
    name: "affidoCulturale",
    models: count,
    plugins: [persistPlugin],
  });

  const persistor = getPersistor();

  // se non è mobile ritorna il sito web desktop
  // const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 600);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsLargeScreen(window.innerWidth > 600);
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  // if (isLargeScreen == true) {
  //   window.location.href = "https://affidoculturale.org/";
  // }
  // end desktop

  // useEffect(() => {
  //   if ("caches" in window) {
  //     caches.keys().then((names) => {
  //       // Delete all the cache files
  //       names.forEach((name) => {
  //         caches.delete(name);
  //       });
  //     });

  //     // Makes sure the page reloads. Changes are only visible after you refresh.
  //     // window.location.reload(true);
  //   }
  // }, []);

  const reloadCache = () => {
    if ("caches" in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });

      // Makes sure the page reloads. Changes are only visible after you refresh.
      window.location.reload(true);
    }
  };

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <Routes>
              <Route path="/" element={<EventiIntorno />} />
              <Route path="/home" element={<Home />} />
              <Route path="/eventi-intorno" element={<EventiIntorno />} />
              <Route path="/cerca" element={<Cerca />} />
              <Route path="/altro" element={<Altro />} />
              <Route path="/preferiti" element={<Preferiti />} />
              <Route path="/profilo" element={<Profilo />} />
              <Route path="/category" element={<Category />} />
              <Route path="/event-detail/:id" element={<EventDetail />} />
              <Route path="/log-in" element={<LogIn />} />
              <Route path="sign-up" element={<SignUp />} />
              <Route path="/affido-culturale" element={<AffidoCulturale />} />
              <Route path="/credits" element={<Credits />} />
              <Route path="/tutti-eventi" element={<AllEvents />} />
              <Route path="/notifiche" element={<Notification />} />
              <Route path="/lista-eventi" element={<EventsListFiltred />} />
              <Route path="/tutorial" element={<Tutorial />} />
            </Routes>
          </ThemeProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}
export default App;
