import { IconButton, Paper } from "@mui/material";
import TopBarComponent from "../../components/TopBarComponent";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router";
import { Button } from "@mui/base";

const AffidoCulturale = () => {
  const navigate = useNavigate();
  return (
    <TopBarComponent>
      <div className="pt-14">
        <div
          className="pt-8"
          style={{
            backgroundColor: "#A1DADB",
            backgroundImage:
              "linear-gradient(180deg, rgba(161,218,219,1) 0%, rgba(255,255,255,1) 100%)",
            marginTop: -30,
          }}
        >
          <div className="w-full py-2 pl-4">
            <IconButton
              className="mb-4 border border-black p-10"
              onClick={() => navigate("/altro")}
              sx={{ width: "20px", borderRadius: "50px" }}
            >
              <div className="flex flex-row items-center text-sm bg-white px-4 py-1 shadow-md rounded-lg ml-9">
                <ArrowBackIcon />
              </div>
            </IconButton>
          </div>
          <div className="pb-3 pt-1 px-3">
            <p className="font-bold text-3xl text-center">
              Che Cos'è Affido Culturale
            </p>
          </div>
        </div>

        <div className="px-3">
          <p className="text-sm text-center py-2 px-2">
            Affido Culturale è un progetto selezionato dall'impresa sociale Con
            I Bambini nell'ambito del Fondo di contrasto alla povertà educativa
            minorile. AC promuove uno scambio solidale tra famiglie che,
            insieme, realizzano “uscite” per bambini e adolescenti, in 4 città:
            Napoli, Roma, Bari, Modena. Ma oggi anche a Milano, Teramo, Arezzo,
            Cagliari, Andria, Palermo, Venezia. Si può andare al cinema, al
            teatro, al museo, al parco, in fattoria didattica, in qualsiasi
            spazio culturale che sia sicuro e convenzionato col progetto. AC
            favorisce concretamente le fruizioni culturali con un aiuto
            economico su biglietti, merenda e trasporto. Vuoi saperne di più?
            Contattaci!
          </p>
        </div>
        <div className="pb-4">
          <p className="font-bold text-3xl text-center">Contatti</p>
        </div>
        <div className="text-center text-gray-500 pb-4 text-sm">
          <div className="py-2">
            <p className="font-bold">Per info sul progetto</p>
            <p>affidoculturale@gmail.com</p>
          </div>
          <div className="py-2">
            <p className="font-bold">Per portare AC nella tua città</p>
            <p>disseminazioneaffidoculturale@gmail.com</p>
          </div>
          <div className="py-2">
            <p className="font-bold">Per ufficio stampa, web, social</p>
            <p>comunicazioneaffidoculturale@gmail.com</p>
          </div>
        </div>
      </div>
    </TopBarComponent>
  );
};
export default AffidoCulturale;
