import * as React from "react";
import TopBarComponent from "../../components/TopBarComponent";
import useSWR, { mutate } from "swr";
import api from "../../conf/api";
import { connect } from "react-redux";
import { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Loading from "../../components/Loading";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { ListItemAvatar } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import cestino from "../../img/ico/delete.png";
import chat from "../../img/ico/chat.png";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

const Notification = (props) => {
  api.setHeaders({
    Authorization: "Bearer " + props.count.token,
    latitude: props.count.latitude,
    longitute: props.count.longitude,
  });

  const { data: messages, error: error_messages } = useSWR(
    props.count.token ? "/inbox-index" : null,
    (url) =>
      api.get(url).then((res) => {
        return res.data.data;
      })
  );

  const date = moment().format("DD/MM/YYYY");

  // console.log(date);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    mutate("/inbox-index");
  };

  const [openDelete, setOpenDelete] = useState(false);
  const handleCloseDelete = () => {
    setOpenDelete(false);
    mutate("/inbox-index");
  };

  const [openDeleteAll, setOpenDeleteAll] = useState(false);
  const handleCloseDeleteAll = () => {
    setOpenDeleteAll(false);
    mutate("/inbox-index");
  };

  const [notice, setNotice] = useState();
  const [deleteDialog, setDeleteDialog] = useState();

  const showNotice = (values) => {
    api
      .get(`/inbox-show/${values}`)
      .then((res) => {
        setNotice(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteNotice = (values) => {
    api
      .get(`/inbox-delete/${values}`)
      .then((res) => {
        mutate("/inbox-index");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteAllMessage = () => {
    api
      .get(`/inbox-delete/all`)
      .then((res) => {
        mutate("/inbox-index");
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const navigate = useNavigate();
  if (!messages) return <Loading />;

  return (
    <TopBarComponent>
      {/* {messages.map((el) => console.log(el.readed))} */}

      <div>
        {/* <div className="flex justify-end pb-2">
          <Button variant="contained" onClick={deleteAllMessage}>
            <span className="text-white">Rimuovi tutto</span>
          </Button>
        </div> */}

        <div
          className="pt-14 pb-2"
          style={{
            backgroundColor: "#A1DADB",
            backgroundImage:
              "linear-gradient(180deg, rgba(161,218,219,1) 0%, rgba(255,255,255,1) 100%)",
          }}>
          <div className="pl-4">
            <IconButton
              className="mb-4 border border-black p-10"
              onClick={() => navigate("/altro")}
              sx={{ width: "20px", borderRadius: "50px" }}>
              <div className="flex flex-row items-center text-sm bg-white px-4 py-1 shadow-md rounded-lg ml-9">
                <ArrowBackIcon />
              </div>
            </IconButton>
          </div>

          <div className="flex flex-row justify-between px-2 pt-2">
            <div className="pl-2">
              <p className="font-bold text-xl">
                Notifiche ({_.size(messages)})
              </p>
            </div>
            {_.size(messages) > 0 && (
              <div>
                <p
                  onClick={() => setOpenDeleteAll(true)}
                  className="underline font-bold text-sm text-center inline-block align-middle cursor-pointer pr-2"
                  style={{ color: "#FF9100" }}>
                  Elimina tutto
                </p>
              </div>
            )}
          </div>
        </div>
        <List sx={{ width: "100%" }}>
          {messages.map((el, index) => (
            <ListItem
              key={index}
              disableGutters
              secondaryAction={
                <ListItemAvatar sx={{ paddingRight: 1 }}>
                  <IconButton
                    onClick={() => {
                      // deleteNotice(el.id);
                      // setDeleteDialog(true);
                      setOpenDelete(true);
                    }}>
                    <img
                      src={cestino}
                      alt="img"
                      className="object-contain px-2 pt-4 pb-0"
                      style={{ width: "25px", padding: 0 }}
                    />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setOpen(true);
                      showNotice(el.id);
                    }}>
                    <img
                      src={chat}
                      alt="img"
                      className="object-contain px-2 pt-4 pb-0"
                      style={{ width: "25px", padding: 0 }}
                    />
                  </IconButton>

                  {/* <IconButton>
                    <img
                      src={chat}
                      alt="img"
                      className="object-contain px-2 pt-4 pb-0"
                      style={{ width: "40px" }}
                    />
                  </IconButton> */}
                </ListItemAvatar>
              }>
              {!el.readed == 1 ? (
                <IconButton>
                  <FiberManualRecordIcon
                    sx={{ color: "#ff9100", padding: 0, fontSize: "15px" }}
                  />
                </IconButton>
              ) : (
                <div className="px-4" />
              )}
              <Button
                sx={{
                  padding: "0px",
                  minWidth: "0px",
                  textTransform: "none",
                  color: "black",
                  textAlign: "left",
                }}
                onClick={() => {
                  setOpen(true);
                  showNotice(el.id);
                }}>
                <ListItemText
                  className="pr-10 pl-2"
                  primary={el.title}
                  primaryTypographyProps={{
                    fontWeight: el.readed == 1 ? "normal" : "bold",
                  }}
                />
              </Button>
            </ListItem>
          ))}
        </List>

        {/* <Dialog
        onClose={() => setDeleteDialog(false)}
        aria-labelledby="customized-dialog-title"
        open={deleteDialog}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => setDeleteDialog(false)}
        >
          <span className="text-md">
            {" "}
            Sei sicuro di voler cancellare il messaggio?
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <div className="flex flex-row justify-center gap-4">
            <Button onClick={() => deleteNotice(el.id)} className="shadow-lg">
              SI
            </Button>
            <Button className="shadow-lg">NO</Button>
          </div>
        </DialogContent>
      </Dialog> */}

        <Dialog
          onClose={handleClose}
          open={open}
          fullWidth
          PaperProps={{
            style: {
              borderRadius: "30px",
            },
          }}>
          <div
            id="customized-dialog-title"
            onClose={handleClose}
            sx={{ backgroundColor: "#F18C08" }}
            className="bg-[#F18C08]">
            <div className="flex justify-between py-2">
              <div
                className="pt-1 text-white mt-1 font-bold"
                style={{ paddingLeft: "24%" }}>
                Dettaglio messaggio
              </div>
              <div className="mr-2">
                <IconButton onClick={handleClose} sx={{ color: "white" }}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </div>
          <DialogContent dividers>
            {notice && (
              <div>
                <div className="flex justify-end text-sm">
                  {moment(notice.created_at).format("DD/MM/YYYY")}
                </div>
                <div className="py-2">
                  <p className="font-bold text-center">
                    {notice && notice.title}
                  </p>
                </div>
                <div
                  className="text-center"
                  dangerouslySetInnerHTML={{
                    __html: `${notice.message}`,
                  }}
                />
              </div>
            )}

            <div className="flex flex-col justify-center gap-3 pb-3 pt-5">
              <button
                variant="contained"
                className="bg-[#F18C08] py-2 rounded-lg shadow-md font-bold"
                onClick={() =>
                  navigate("/lista-eventi", {
                    state: {
                      date: date ? date : null,
                    },
                  })
                }>
                <span className="text-white normal-case">
                  Scopri gli eventi disponibili
                </span>
              </button>

              <button
                variant="outlined"
                onClick={() => {
                  deleteNotice(notice.id);
                  setOpen(false);
                }}
                className="border border-[#F18C08] py-2 rounded-lg text-[#F18C08] font-bold">
                <span className="normal-case">Elimina messaggio</span>
              </button>
            </div>
          </DialogContent>
        </Dialog>

        {/* delete messaggio */}
        <Dialog
          onClose={handleCloseDelete}
          open={openDelete}
          fullWidth
          PaperProps={{
            style: {
              borderRadius: "30px",
            },
          }}>
          <div
            id="customized-dialog-title"
            onClose={handleCloseDelete}
            sx={{ backgroundColor: "#F18C08" }}
            className="bg-[#F18C08]">
            <div className="flex justify-between py-2">
              <div
                className="pt-1 text-white mt-1 font-bold"
                style={{ paddingLeft: "36%" }}>
                Sei sicuro?
              </div>
              <div className="mr-2">
                <IconButton onClick={handleCloseDelete} sx={{ color: "white" }}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </div>
          <DialogContent dividers>
            <div className="flex flex-row justify-center gap-3 pb-3 pt-5">
              <button
                variant="contained"
                className="border border-[#F18C08] py-2 rounded-lg font-bold px-5 text-[#F18C08]  w-full"
                onClick={() => {
                  deleteNotice(notice.id);
                  handleCloseDelete();
                }}>
                <span className=" normal-case">Si</span>
              </button>

              <button
                variant="outlined"
                onClick={handleCloseDelete}
                className=" bg-[#F18C08] py-2 rounded-lg font-bold px-5 w-full">
                <span className="normal-case text-white">No</span>
              </button>
            </div>
          </DialogContent>
        </Dialog>

        {/* delete tutti i messaggio */}
        <Dialog
          onClose={handleCloseDeleteAll}
          open={openDeleteAll}
          fullWidth
          PaperProps={{
            style: {
              borderRadius: "30px",
            },
          }}>
          <div
            id="customized-dialog-title"
            onClose={handleCloseDeleteAll}
            sx={{ backgroundColor: "#F18C08" }}
            className="bg-[#F18C08]">
            <div className="flex justify-between py-2">
              <div
                className="pt-1 text-white mt-1 font-bold text-center"
                style={{ paddingLeft: "20%" }}>
                Vuoi cancellare tutti i messaggi?
              </div>
              <div className="mr-2">
                <IconButton
                  onClick={handleCloseDeleteAll}
                  sx={{ color: "white" }}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </div>
          <DialogContent dividers>
            <div className="flex flex-row justify-center gap-3 pb-3 pt-5">
              <button
                variant="contained"
                className="border border-[#F18C08] py-2 rounded-lg shadow-md text-[#F18C08]  font-bold px-5 w-full"
                onClick={() => {
                  deleteAllMessage();
                  handleCloseDeleteAll();
                }}>
                <span className=" normal-case">Si</span>
              </button>

              <button
                variant="outlined"
                onClick={handleCloseDeleteAll}
                className=" bg-[#F18C08] py-2 rounded-lg font-bold px-5 w-full">
                <span className="normal-case text-white">No</span>
              </button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </TopBarComponent>
  );
};
const mapState = (state) => ({
  count: state.count,
});
export default connect(mapState)(Notification);
