import React, { useEffect, useState } from "react";
import CardComponent from "../components/CardComponent";
import { useLocation } from "react-router-dom";
import TopBarComponent from "../components/TopBarComponent";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import useSWR, { mutate } from "swr";
import api from "./../conf/api";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Loading from "../components/Loading";
import { useGeolocated } from "react-geolocated";
import { connect } from "react-redux";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import _ from "lodash";
import axios from "axios";
import icon_notfound from "../img/ico/icon_notfound.png";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Box from "@mui/material/Box";

const EventsListFiltred = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0); // Assicurati di tornare in cima alla pagina al cambio di pagina
  }, []);

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  const [page, setPage] = useState(1);
  const [reload, setReload] = useState(false);

  api.setHeaders({
    Authorization: "Bearer " + props.count.token,
    latitude: props.count.latitude,
    longitude: props.count.longitude,
  });

  useEffect(() => {
    updateAPI();
    if (coords) {
      props.dispatch.count.setLatitude(coords.latitude);
      props.dispatch.count.setLongitude(coords.longitude);
    }
  }, [coords]);

  const location = useLocation();
  const eventResult = location.state && location.state;

  // Utilizza il valore del parametro come desideri

  // console.log( {...eventResult, page: page}, page)

  // const { data: events, error: errorEvents } = useSWR(
  //   ["/events-search"],
  //   (url) =>
  //     api.post(url, { ...eventResult, page: page }).then((res) => {
  //       // console.log(page)
  //       return res.data.data;
  //     })
  // );

  // const { data: events, error: errorEvents } = useSWR("/events-search", (url) =>
  //   api.post(url, { ...eventResult, page: page}).then((res) => res.data.data)
  // );

  const [events, setEvents] = useState();

  const updateAPI = (pageParams) => {
    const url = "/events-search";
    const requestData = {
      ...eventResult,
      page:
        !pageParams && pageParams !== false
          ? 1
          : pageParams
          ? page + 1
          : pageParams === false && page - 1,
    };

    api
      .post(url, requestData)
      .then((res) => {
        const updatedData = res.data.data;
        setEvents(updatedData);

        mutate(url, updatedData); // Aggiorna i dati nella cache
      })
      .catch((error) => {
        console.log(error);
        // Gestisci eventuali errori di aggiornamento dell'API
      });
  };

  // const fetcher = (url) =>
  //   axios
  //     .post(
  //       url,
  //       { ...eventResult, page: page },
  //       {
  //         headers: {
  //           Authorization: "Bearer " + props.count.token,
  //           latitude: props.count.latitude,
  //           longitude: props.count.longitude,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       return res.data.data;
  //     });
  // const { data: events, error: errorEvents } = useSWR(
  //   [`https://staging.affidoculturale.org/api/v2/events-search`, page],
  //   (url, page) => fetcher(url, page)
  // );

  const { data: me, error: errorMe } = useSWR("/me", (url) =>
    api.get(url).then((res) => res.data.data.user)
  );

  const navigate = useNavigate();
  if (!events) return <Loading />;

  return (
    <TopBarComponent>
      {_.size(events.data) > 0 ? (
        <div>
          <div
            className="flex flex-col justify-center gap-4 mb-3 py-11 px-5"
            style={{
              backgroundColor: "#A1DADB",
              backgroundImage:
                "linear-gradient(180deg, rgba(161,218,219,1) 0%, rgba(255,255,255,1) 100%)",
            }}>
            {!isGeolocationEnabled && (
              <div className="flex justify-center">
                La geolocalizzazione è disabilitata
              </div>
            )}
            <div className="flex flex-row justify-between px-2 pt-2">
              <Box
                sx={{
                  "& .MuiButtonBase-root": {
                    backgroundColor: "white",
                  },
                  "& .css-diy3lx-MuiButtonBase-root-MuiButton-root:hover": {
                    backgroundColor: "white",
                  },
                }}>
                <Button
                  onClick={() => navigate(-1)}
                  className="shadow-md"
                  sx={{
                    borderRadius: "10px",
                    // backgroundColor: "white",
                  }}>
                  <ArrowBackIcon className="text-black" />
                </Button>
              </Box>
              {isGeolocationEnabled && me && (
                <div className="flex text-sm">
                  <LocationOnIcon sx={{ fontSize: "20px" }} />
                  <p>{me && me.city}</p>
                </div>
              )}
            </div>
            <div className="pt-3 pb-2">
              <p className="font-bold text-2xl px-2">Risultati della ricerca</p>
            </div>
          </div>

          {events.data.map((el, index) => {
            return (
              <React.Fragment key={index}>
                <div className="px-5 pb-7">
                  <CardComponent
                    typeCard={"topCard"}
                    id={el.id}
                    res={el}
                    img={el.image_url}
                    nameEvent={el.title}
                    typeActivity={el.category.title}
                    filtered={true}
                  />
                </div>
              </React.Fragment>
            );
          })}
        </div>
      ) : (
        <div>
          <div
            className="flex flex-col justify-center gap-4 mb-3 px-5"
            style={{
              backgroundColor: "#A1DADB",
              backgroundImage:
                "linear-gradient(180deg, rgba(161,218,219,1) 0%, rgba(255,255,255,1) 100%)",
            }}></div>
          <div className="h-screen px-24 flex flex-col justify-center">
            <div>
              <img src={icon_notfound} alt="" />
            </div>
            <p className="font-bold text-center">
              Nessun evento disponibile per la categoria selezionata!
            </p>
            <div className="pt-4 flex justify-center">
              <button
                className="bg-[#F18C09] text-white px-5 py-2 rounded-lg text-sm font-bold"
                onClick={() => navigate("/cerca")}>
                Torna alla ricerca
              </button>
            </div>
          </div>
        </div>
      )}

      {/* button pagination */}
      <div className="flex flex-row pb-10 justify-center gap-3">
        {page > 1 && (
          <div className="bg-white flex justify-center p-3 rounded-lg shadow-lg">
            <button
              onClick={() => {
                setPage((val) => val - 1);
                updateAPI(false);
                window.scrollTo(0, 0);
              }}>
              <ArrowBackIosNewIcon />
            </button>
          </div>
        )}
        {_.size(events.data) > 12 && (
          <div className="bg-white p-3 rounded-lg shadow-lg">
            <button
              onClick={() => {
                setPage((val) => val + 1);
                updateAPI(true);
                window.scrollTo(0, 0);
              }}>
              <ArrowForwardIosIcon />
            </button>
          </div>
        )}
      </div>
    </TopBarComponent>
  );
};
const mapState = (state) => ({
  count: state.count,
});
export default connect(mapState)(EventsListFiltred);
