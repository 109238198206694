export const count = {
  state: {
    token: "",
    email: "",
    longitude: "",
    latitude: "",
    logged: "",
    push: 0,
    dataCalendar: null,
    etaId: null,
    etaTitle: null,
    ente: null,
  },
  reducers: {
    addToken: (state, token) => {
      return { ...state, token };
    },
    addEmail: (state, email) => {
      return { ...state, email };
    },
    setLongitude: (state, longitude) => {
      return { ...state, longitude };
    },
    setLatitude: (state, latitude) => {
      return { ...state, latitude };
    },
    setLogged: (state, logged) => {
      return { ...state, logged };
    },
    setDataCalendar: (state, dataCalendar) => {
      return { ...state, dataCalendar };
    },
    setPush: (state, push) => {
      return { ...state, push };
    },
    setEtaId: (state, etaId) => {
      return { ...state, etaId };
    },
    setEtaTitle: (state, etaTitle) => {
      return { ...state, etaTitle };
    },
    setEnte: (state, ente) => {
      return { ...state, ente };
    },
  },
};
