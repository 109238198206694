import React from "react";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { Paper } from "@mui/material";

const BoxMenu = ({ title, icon, onClickCustom }) => {
  return (
    <Paper onClick={onClickCustom} sx={{ borderRadius: "15px" }} elevation={6}>
      <div className="flex flex-row items-center justify-between p-4 m-4 rounded-full">
        <div className="flex flex-row items-center gap-4">
          <div>{icon}</div>
          <div className="items-center text-[#F18C09]">{title}</div>
        </div>
        <ArrowForwardRoundedIcon sx={{ color: "#F18C09" }} />
      </div>
    </Paper>
  );
};

export default BoxMenu;
