import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, ListItemButton, TextField } from "@mui/material";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import StarIcon from "@mui/icons-material/Star";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import { Link, useLocation, useNavigate, useRoutes } from "react-router-dom";
import _ from "lodash";
import logoAC from "../img/AC_logo_orizzontale.png";
import logoBimbi from "../img/bimbi_logo.png";
import { connect } from "react-redux";
import useSWR, { mutate } from "swr";
import api from "../conf/api";

// const drawerWidth = 180;

// const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
//   ({ theme, open }) => ({
//     flexGrow: 1,
//     // padding: theme.spacing(3),
//     transition: theme.transitions.create("margin", {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     marginRight: -drawerWidth,
//     ...(open && {
//       transition: theme.transitions.create("margin", {
//         easing: theme.transitions.easing.easeOut,
//         duration: theme.transitions.duration.enteringScreen,
//       }),
//       marginRight: 0,
//     }),
//   })
// );

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   transition: theme.transitions.create(["margin", "width"], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(["margin", "width"], {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//     marginRight: drawerWidth,
//   }),
// }));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const TopBarComponent = ({ children, count, dispatch }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const ref = React.useRef(null);
  const navigate = useNavigate();
  const route = useLocation();
  // console.log(count);
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const { data: messages, error: error_messages } = useSWR(
    count.token ? "/inbox-index" : null,
    (url) =>
      api.get(url).then((res) => {
        if (_.isEmpty(res.data.data)) {
          dispatch.count.setPush(0);
        } else {
          res.data.data.map((el) => {
            if (el.readed == 0) {
              dispatch.count.setPush(1);
            } else {
              dispatch.count.setPush(0);
            }
          });
        }
        return res.data.data;
      })
  );

  // console.log(count);

  React.useEffect(() => {
    if (route.pathname === "/") {
      setValue(0);
    }
    if (route.pathname === "/cerca") {
      setValue(1);
    }
    if (route.pathname === "/preferiti") {
      setValue(2);
    }
    if (route.pathname === "/profilo" || route.pathname === "/log-in") {
      setValue(3);
    }
    if (
      route.pathname === "/altro" ||
      route.pathname === "/notifiche" ||
      route.pathname === "/affido-culturale" ||
      route.pathname === "/credits"
    ) {
      setValue(4);
    }
    ref.current.ownerDocument.body.scrollTop = 0;
  }, [value]);

  // Stile personalizzato per BottomNavigationAction
  const CustomBottomNavigationAction = styled(BottomNavigationAction)`
    &.MuiBottomNavigationAction-root {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      background-color: red;
    }
  `;

  return (
    <Box
      className="md:max-w-[400px] m-auto"
      sx={{
        display: "flex",
        // paddingLeft:70,
        // paddingRight:70,
        ".css-ogjlsp-MuiPaper-root-MuiAppBar-root": {
          backgroundColor: "white",
        },
      }}
    >
      <CssBaseline />
      {/* AppBar -> navbar */}
      <AppBar position="fixed" open={open} 
          sx={{ backgroundColor: "white" }}>
        <Toolbar
          className="md:max-w-[400px] m-auto"
          sx={{ backgroundColor: "white" }}>
          <div className="flex flex-row justify-center w-full items-center">
            <div
              // onClick={() => navigate("/")}
              onClick={() => window.location.href="https://affidoculturale.org/"} 
              className="flex flex-row items-center py-2"
            >
              {/* <IconButton sx={{ width: "30px", marginRight: "10px" }}>
                <ArrowBackIcon />
              </IconButton> */}
              <img className="w-28 object-contain" src={logoBimbi} alt="img" />
              <img className="w-3/5 object-contain h-20" src={logoAC} alt="img" />
            </div>
            {/* <IconButton
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
              sx={{ ...(open && { display: "none" }) }}>
              <MenuIcon />
            </IconButton> */}
          </div>
        </Toolbar>
      </AppBar>

      <div className="z-index z-99 w-full">
        <DrawerHeader />
        <Box sx={{ pb: 7 }} ref={ref}>
          <CssBaseline />
          {children}
          <Paper
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              paddingTop: 0,
              paddingBottom: 1.2,
            }}
            elevation={3}
          >
            <BottomNavigation

              className="md:max-w-[400px] m-auto"
              showLabels
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            >
              <BottomNavigationAction
                sx={{
                  paddingLeft: 2,
                }}
                component={Link}
                to="/"
                label="Eventi"
                icon={<CalendarMonthIcon />}
              />
              <BottomNavigationAction
                component={Link}
                to={"/cerca"}
                label="Cerca"
                icon={<SearchIcon />}
              />
              <BottomNavigationAction
                component={Link}
                to={count.token ? "/preferiti" : "/log-in"}
                label="Preferiti"
                icon={<StarIcon />}
              />
              <BottomNavigationAction
                component={Link}
                to={count.token ? "/profilo" : "/log-in"}
                label="Profilo"
                icon={<PersonIcon />}
              />
              <BottomNavigationAction
                sx={{
                  paddingRight: 2,
                }}
                component={Link}
                to="/altro"
                label="Altro"
                icon={
                  <div className="relative">
                    <MenuIcon />
                    {count.push == 1 && (
                      <div
                        className="absolute"
                        style={{
                          bottom: "61%",
                          left: "70%",
                          width: "10px",
                          height: "10px",
                          backgroundColor: "#F18C23",
                          borderRadius: "50%",
                        }}
                      />
                    )}
                  </div>
                }
              />
            </BottomNavigation>
          </Paper>
        </Box>
      </div>
    </Box>
  );
};

const mapState = (state) => ({
  count: state.count,
});
export default connect(mapState)(TopBarComponent);
