// import * as React from "react";
import React, { useState, useEffect } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import DoneIcon from "@mui/icons-material/Done";
import StarIcon from "@mui/icons-material/Star";
import {
  Card,
  CardActions,
  CardMedia,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
} from "@mui/material";
import { useNavigate } from "react-router";
import api from "../conf/api";
import _ from "lodash";
import { mutate } from "swr";
import CameraCapture from "./CameraCapture";
import { connect } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Loading from "./Loading";
import moment from "moment";
import success from "../img/ico/Status_success.png";

const CardComponent = ({
  id,
  img,
  typeActivity,
  typeCard,
  nameEvent,
  res,
  preferiti,
  pref,
  count,
  data_svolto,
  data_svolto_profile,
  setOpenSuccessP,
  openSuccessP,
  showDiv,
  filtered,
  index,
}) => {
  const [colorLabel, setColorLabel] = useState("#9BD2A8");
  const [star, setStar] = useState(res.bookmarks_selected);
  const navigate = useNavigate();
  const [openSuccess, setOpenSuccess] = useState(false);

  const [addFavourite, setAddFavourite] = useState();

  const [openUpload, setOpenUpload] = useState(false);
  const handleClickOpenUpload = () => {
    setOpenUpload(true);
  };
  const handleCloseUpload = () => {
    setOpenUpload(false);
  };

  const [idBanner, setIdBanner] = useState();

  // console.log(pref);

  if (!res) return <Loading />;

  return (
    <React.Fragment>
      <div className="h-full shadow-lg " style={{ borderRadius: "25px" }}>
        <Card
        //  onClick={() => navigate("/event-detail/" + id)}
          id={id}
          sx={{
            borderRadius: "25px",
            minWidth: "161.5px",
            height: "100%",
            position: "relative",
          }}
        >
          <div className="flex flex-col">
            <CardMedia
              onClick={() => navigate("/event-detail/" + id)}
              component="img"
              image={img}
              className="h-32"
            />

            {/* star topCard*/}
            {count.token && (
              <>
                {(typeCard == "topCard" || typeCard == "profile") && (
                  <IconButton
                    type="button"
                    onClick={() =>
                      api
                        .post("/bookmarked", { id: id })
                        .then((res) => {
                          res.data.data.added ? setStar(true) : setStar(false);
                          mutate("/bookmarked");
                          mutate("/events-favorites");
                        })
                        .catch((err) => console.log(err))
                    }
                    className="relative bg-white p-1 align-middle shadow-lg"
                    style={{
                      borderRadius: "10px",
                      left: "80%",
                      // bottom: pref ? "10.5rem" : "13.5rem",
                      bottom: "7.5rem",
                      backgroundColor: "white",
                      width: "50px",
                    }}
                  >
                    {!star && !pref ? (
                      <StarBorderIcon
                        sx={{ width: "20px", height: "20px" }}
                        className="text-gray-400"
                      />
                    ) : (
                      <StarIcon
                        sx={{ width: "20px", height: "20px" }}
                        className="text-yellow-500"
                      />
                    )}
                  </IconButton>
                )}
              </>
            )}
            {/* end */}

            {/* star menoRecenti */}
            {count.token && typeCard == "menoRecenti" && (
              <IconButton
                type="button"
                onClick={() =>
                  api
                    .post("/bookmarked", { id: id })
                    .then((res) => {
                      res.data.data.added ? setStar(true) : setStar(false);
                      mutate("/bookmarked");
                      mutate("/events-favorites");
                    })
                    .catch((err) => console.log(err))
                }
                className="absolute bottom-24 left-28  bg-white p-1 align-middle shadow-lg"
                style={{
                  borderRadius: "10px",
                  left: "70%",
                  bottom: "7.5rem",
                  backgroundColor: "white",
                  width: "40px",
                }}
              >
                {!star ? (
                  <StarBorderIcon
                    sx={{ width: "20px", height: "20px" }}
                    className="text-gray-400"
                  />
                ) : (
                  <StarIcon
                    sx={{ width: "20px", height: "20px" }}
                    className="text-yellow-500"
                  />
                )}
              </IconButton>
            )}
            {/* end */}

            {/* star Row */}
            {(typeCard == "grid" || typeCard == "row") && (
              <IconButton
                type="button"
                onClick={() =>
                  typeActivity !== "svolte" &&
                  api
                    .post("/bookmarked", { id: id })
                    .then((res) => {
                      res.data.data.added ? setStar(true) : setStar(false);
                      mutate("/bookmarked");
                      mutate("/events-favorites");
                    })
                    .catch((err) => console.log(err))
                }
                className="relative bottom-24 left-28  bg-white p-1 align-middle shadow-lg"
                style={{
                  borderRadius: "10px",
                  // left: "200%",
                  // bottom: "360%",
                  left: "70%",
                  bottom: "7.5rem",
                  backgroundColor: "white",
                  width: "40px",
                }}
              >
                {typeActivity == "svolte" ? (
                  <DoneIcon
                    sx={{ width: "20px", height: "20px" }}
                    className="text-green-500"
                  />
                ) : !star ? (
                  <StarBorderIcon
                    sx={{ width: "20px", height: "20px" }}
                    className="text-gray-400"
                  />
                ) : (
                  <StarIcon
                    sx={{ width: "20px", height: "20px" }}
                    className="text-yellow-500"
                  />
                )}
              </IconButton>
            )}
            {/* end */}

            <div
              style={{
                backgroundColor: `${res.category && res.category.color}`,
                borderRadius: "0px 5px 0px 0px",
                bottom: !count.token ? "1.45rem" : "59px",
              }}
              className={`relative w-28 h-6 pl-2 flex flex-row items-center text-white`}
            >
              {res.category && (
                <img
                  className="object-contain h-5 w-5"
                  alt={"img"}
                  src={res.category_path + res.category.photo_url}
                  style={{ paddingBottom: "2px" }}
                />
              )}
              <div
                className={`uppercase w-full text-xs text-dark pl-1 max-w-xs `}
              >
                <span className="">{res && res.cat_name}</span>
              </div>
            </div>
            <div className="px-5 text-sm">
              {filtered == true && (
                <>
                  da {moment(res.date_start).format("DD-MM-YYYY")} a{" "}
                  {moment(res.date_end).format("DD-MM-YYYY")}
                </>
              )}
            </div>
            <div className="flex px-5 text-ellipsis overflow-hidden w-full max-h-820">
              <p
                onClick={() => navigate("/event-detail/" + id)}
                className="font-bold"
              >
                {nameEvent}
              </p>
            </div>
            {typeActivity == "svolte" ? (
              <div>
                <p className="px-5">{data_svolto}</p>
              </div>
            ) : (
              <></>
            )}
            {typeCard == "profile" ? (
              <div>
                <p className="px-5">{data_svolto_profile}</p>
              </div>
            ) : (
              <></>
            )}
            {typeCard == "topCard" && !pref && (
              <div className="px-5 text-xs pt-2">
                <p>
                  {_.size(res.short_description) > 10
                    ? res.short_description.substring(0, 50) + "..."
                    : ""}
                </p>
              </div>
            )}
          </div>

          {typeCard !== "noButton" && (
            <CardActions
              className={`flex ${
                typeCard == "profile" ? "justify-between" : "justify-end"
              } `}
            >
              {typeCard == "profile" && (
                <>
                  <div
                    onClick={() => setOpenUpload(true)}
                    className="w-full flex flex-row items-center justify-center gap-2 border-t pt-2"
                  >
                    <AddIcon sx={{ color: "#f18c08" }} />
                    <div className="text-[#f18c08] font-bold">Aggiungi feedback</div>
                  </div>
                  <Dialog
                    PaperProps={{
                      style: {
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        margin: "auto",
                        maxHeight: "80vh",
                        borderRadius: "25px 25px 0 0",
                      },
                    }}
                    open={openUpload}
                    onClose={() => setOpenUpload(false)}
                    sx={{
                      ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
                        borderRadius: 6,
                      },
                      ".css-1bet5az-MuiTypography-root-MuiDialogTitle-root": {
                        backgroundColor: "#F18C08",
                      },
                      ".css-bdhsul-MuiTypography-root-MuiDialogTitle-root": {
                        padding: 1,
                      },
                    }}
                  >
                    <DialogTitle
                      onClose={() => setOpenUpload(false)}
                      className="bg-[#F18C08] text-white"
                    >
                      <div className="flex flex-row items-center w-full justify-between px-4">
                        <div className="text-center font-bold text-sm pl-20 py-2">
                          <p className="text-xl">Carica foto evento</p>
                        </div>
                        <CloseIcon onClick={() => setOpenUpload(false)} />
                        {/* <div></div>
                      <div></div> */}
                      </div>
                    </DialogTitle>
                    <DialogContent>
                      <CameraCapture
                        setClose={setOpenUpload}
                        setOpenSuccess={setOpenSuccess}
                        setOpenSuccessP={setOpenSuccessP}
                        id={id}
                        setIdBanner={setIdBanner}
                      />
                    </DialogContent>
                  </Dialog>
                </>
              )}
            </CardActions>
          )}
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={openSuccessP}
            autoHideDuration={3000}
            sx={{ marginTop: 8 }}
            onClose={() => setOpenSuccessP(false)}
          >
            <div
              id="myDiv"
              className="flex flex-row justify-between rounded-3xl shadow-lg py-5 my-3"
              style={{
                background: "#FFFFFF",
                background:
                  "radial-gradient(at center, #FFFFFF, rgba(161,218,219,0.3))",
              }}
            >
              <div
                className="pl-3 "
                style={{ display: "flex", alignItems: "center" }}
              >
                <button>
                  <img src={success} />
                </button>
              </div>
              <div className="pl-2">
                <p className="font-bold text-xs">
                  La tua foto è stata caricata con successo!
                </p>
                <p className="text-xs pt-1">
                  Grazie per aver caricato la tua foto ed averci lasciato un
                  messaggio.
                </p>
              </div>
              <div
                className="pr-5"
                style={{ display: "flex", alignItems: "center" }}
              >
                <button onClick={() => setOpenSuccessP(false)}>
                  <CloseIcon />
                </button>
              </div>
            </div>
          </Snackbar>
          {typeCard == "topCard" && (
            <div
              onClick={() => navigate("/event-detail/" + id)}
              className="flex flex-row gap-2 items-center w-full justify-end mx-2 pr-5 pb-5"
            >
              <div className="underline text-black items-center font-bold text-sm normal-case">
                Scopri di più
              </div>
              <div>
                <ArrowForwardIosIcon sx={{ width: 18 }} />
              </div>
            </div>
          )}
        </Card>
      </div>
    </React.Fragment>
  );
};
const mapState = (state) => ({
  count: state.count,
});
export default connect(mapState)(CardComponent);
