import { Alert, Button, Snackbar, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import TextFieldCustom from "./TextFieldCustom";
import api from "../conf/api";
import gallery from "../img/gallery.png";
import capture from "../img/capture.png";

import Rating from "@mui/material/Rating";

const CameraCapture = ({
  setClose,
  id,
  setOpenSuccess,
  openSuccess,
  setOpenSuccessP,
  setIdBanner,
}) => {
  const videoRef = useRef(null);

  const [start, setStart] = useState(false);
  const [message, setMessage] = useState("");

  const [rating, setRating] = useState(null);

  const handleStartCapture = () => {
    setStart(true);
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      })
      .catch((error) => {
        console.error("Error accessing camera:", error);
      });
  };

  const [photoURL, setPhotoURL] = useState("");

  const handleStopCapture = () => {
    const stream = videoRef.current.srcObject;
    const tracks = stream.getTracks();

    tracks.forEach((track) => {
      track.stop();
    });

    videoRef.current.srcObject = null;
  };

  const handleCapturePhoto = () => {
    const canvas = document.createElement("canvas");
    const video = videoRef.current;

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const context = canvas.getContext("2d");
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    const photoDataUrl = canvas.toDataURL("image/jpeg");
    setPhotoURL(photoDataUrl);

    handleStopCapture();
  };

  const handleUploadPhoto = () => {
    api
      .post("/save-photo-events", {
        id: id,
        message: message,
        img: previewURL ? previewURL : photoURL,
        rating: rating
      })
      .then((res) => {
        // console.log(res.data);
        setOpenSuccess(true);
        setOpenSuccessP(true);
        setIdBanner(id);
        setClose(false);
      })
      .catch((err) => console.log(err));
  };

  const [files, setFiles] = useState(null);
  const [previewURL, setPreviewURL] = useState("");
  console.log("photo Url", photoURL);
  console.log("files", files);

  return (
    <div className={"flex flex-col my-4 w-full h-full"}>
      {!photoURL && (
        <div
          className="border px-3"
          style={{ borderRadius: "10px", borderColor: "#ff9100" }}
        >
          <label
            htmlFor="profile_image"
            className="block w-full font-medium text-md text-[#F18C08] border border-[#F18C08] pl-4 py-1 bg-white rounded-md border-0 text-sm font-semibold flex items-center justify-start cursor-pointer"
          >
            <img
              src={gallery}
              alt="gallery"
              className="object-contain h-8 w-8"
            />
            <span className="pl-2">Scegli file</span>
          </label>
          <input
            id="profile_image"
            accept="image/*"
            // capture="camera"
            onChange={(e) => {
              if (e) {
                setFiles(e.target.files[0]);
                if (e.target.size > 5e6) {
                  window.alert("Insert a file with a size smaller than 5MB");
                }
                const file = e.target.files[0];
                const reader = new FileReader();
                reader.onloadend = () => {
                  const base64String = reader.result;
                  setPreviewURL(base64String);
                };
                reader.readAsDataURL(file);
              }
            }}
            name="profile_image"
            type="file"
            className="hidden"
          />
        </div>
      )}
      {previewURL && (
        <div className="w-full flex justify-center">
          <img src={previewURL} alt="File Preview" className="mt-6 w-56" />
        </div>
      )}
      <div className="py-3 mb-4">
        {photoURL ? (
          <div>
            <img src={photoURL} alt="Captured" className="mb-4" />
            <Button
              onClick={() => setOpenSuccess(true)}
              sx={{
                fontWeight: "bold",
                borderWidth: 2,
                width: "100%",
                color: "white",
              }}
              variant="contained"
            >
              Carica foto
            </Button>
          </div>
        ) : (
          <></>
          // <div>
          //   {start && <video ref={videoRef} autoPlay playsInline />}
          //   {!previewURL && !start && (
          //     <Button
          //       fullWidth
          //       sx={{
          //         fontWeight: "bold",
          //         borderWidth: 2,
          //         borderRadius: "10px",
          //       }}
          //       variant="outlined"
          //       onClick={handleStartCapture}
          //       startIcon={
          //         <img
          //           src={capture}
          //           alt="capture"
          //           className="object-contain h-8 w-8 ml-4"
          //         />
          //       }>
          //       <span className="normal-case pr-36">Avvia cattura</span>
          //     </Button>
          //   )}
          //   {start && (
          //     <div className="flex flex-row gap-4 items-center w-full mt-4">
          //       <Button
          //         sx={{ fontWeight: "bold", borderWidth: 2, width: "100%" }}
          //         variant="outlined"
          //         onClick={handleStopCapture}>
          //         ANNULLA
          //       </Button>
          //       <Button
          //         sx={{
          //           fontWeight: "bold",
          //           borderWidth: 2,
          //           width: "100%",
          //           color: "white",
          //         }}
          //         variant="contained"
          //         onClick={handleCapturePhoto}>
          //         Cattura
          //       </Button>
          //     </div>
          //   )}
          // </div>
        )}
      </div>
      <div className="py-5">
        <Typography component="legend">Vota l'evento</Typography>
        <Rating
          name="simple-controlled"
          value={rating}
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
        />
      </div>
      <div>
        <p className="font-bold">Scrivi messaggio</p>
      </div>
      <TextFieldCustom
        multiline
        rows={3}
        fullWidth
        required
        id="text"
        label="Messaggio"
        name="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        sx={{ borderRadius: "30px" }}
        className="py-10"
      />
      <div className="flex flex-row items-end gap-4 w-full h-full mb-4 pt-5">
        <Button
          sx={{ width: "100%" }}
          onClick={() => setClose(false)}
          variant="outlined"
        >
          Chiudi
        </Button>
        <Button
          disabled={!files && !message}
          sx={{ width: "100%" }}
          onClick={handleUploadPhoto}
          variant="contained"
        >
          <span className="text-white">Salva</span>
        </Button>
      </div>
    </div>
  );
};

export default CameraCapture;
