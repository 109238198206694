import React from "react";
import { Button, Card, CardContent, Divider } from "@mui/material";
import { useState, useEffect } from "react";
import TopBarComponent from "../components/TopBarComponent";
import PlaceIcon from "@mui/icons-material/Place";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { connect } from "react-redux";
import api from "./../conf/api";
import useSWR, { mutate } from "swr";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import ErrorsComponent from "../components/ErrorComponent";
import Loading from "../components/Loading";
import CardComponent from "../components/CardComponent";
import profileImg from "../../src/img/Foto_profilo.png";
import figlioMaschio from "../../src/img/Figlio.png";
import figlioFemmina from "../../src/img/Figlia.png";
import adultoImg from "../../src/img/Adulto.png";
import moneta_educati from "../../src/img/ico/moneta_educati.png";
import CloseIcon from "@mui/icons-material/Close";
import success from "../img/ico/Status_success.png";
import failed from "../img/ico/Status_failed.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLocation } from "react-router-dom";

const Profilo = (props) => {
  api.setHeaders({
    Authorization: "Bearer " + props.count.token,
    latitude: props.count.latitude,
    longitude: props.count.longitude,
  });
  const [showDiv, setShowDiv] = useState(true);
  // useEffect(() => {
  //   setShowDiv(true)
  //   const timer = setTimeout(() => {
  //     setShowDiv(false);
  //   }, 5000); // Nascondi il div dopo 5 secondi (5000 millisecondi)

  //   return () => {
  //     clearTimeout(timer); // Assicurati di cancellare il timer quando il componente viene smontato
  //   };
  // }, []);
  const [openSuccessP, setOpenSuccessP] = useState(false);

  const [page, setPage] = useState(1);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowDiv(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, [openSuccessP]);

  useEffect(() => {
    updateAPI();
  }, []);

  const { data, error } = useSWR("/users", (url) =>
    api.get(url).then((res) => res.data.data)
  );

  // const { data: attivitaSvolte, error: ErrorAttivitaSvolte } = useSWR(
  //   "/events-carried-out",
  //   (url) => api.post(url, { page: 1 }).then((res) => res.data.data)
  // );

  const location = useLocation();
  const eventResult = location.state && location.state;

  const [attivitaSvolte, setAttivitaSvolte] = useState();

  // api modificata perchè con useSwr non funziona bene la pagination
  const updateAPI = (pageParams) => {
    const url = "/events-carried-out";
    const requestData = {
      ...eventResult,
      page:
        !pageParams && pageParams !== false
          ? 1
          : pageParams
          ? page + 1
          : pageParams === false && page - 1,
    };
    api
      .post(url, requestData)
      .then((res) => {
        const updatedData = res.data.data;
        setAttivitaSvolte(res.data.data);

        mutate(url, updatedData); // Aggiorna i dati nella cache
      })
      .catch((error) => {
        console.log(error);
        // Gestisci eventuali errori di aggiornamento dell'API
      });
  };

  const navigate = useNavigate();

  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");

  if (error) return <ErrorsComponent />;
  if (!data) return <Loading />;
  if (!attivitaSvolte) return <Loading />;

  return (
    <TopBarComponent>
      <div
        className="pt-14 pb-8 px-4 flex flex-row gap-8 justify-center"
        style={{
          backgroundColor: "#A1DADB",
          backgroundImage:
            "linear-gradient(180deg, rgba(161,218,219,1) 0%, rgba(255,255,255,1) 100%)",
        }}>
        <div style={{ alignSelf: "center" }}>
          <Card className="px-4" sx={{ borderRadius: "18px" }}>
            <CardContent>
              <div className="pb-1">
                <p className="font-bold text-md">
                  {data.name} {data.surname}
                </p>
              </div>
              <Divider />
              <ul style={{ listStyleType: "none" }} className="pt-1">
                <li>
                  {/* <MonetizationOnIcon
                    style={{ fontSize: "19px" }}
                    className="text-[#C68516]"
                  /> */}
                  <img
                    src={moneta_educati}
                    alt=""
                    style={{ width: "22px", display: "inline" }}
                  />
                  <span>
                    <span className="font-bold">{data.educati}</span> eDucati
                  </span>
                </li>
                <li>
                  <PlaceIcon style={{ fontSize: "19px" }} />
                  <span style={{ fontSize: "15px" }}>{data.city}</span>
                </li>
              </ul>
            </CardContent>
          </Card>
        </div>
        <div>
          <Card
            className=""
            sx={{
              borderRadius: "100px",
              width: "8rem",
              height: "8rem",
              ".css-46bh2p-MuiCardContent-root": {
                paddingBottom: 2,
              },
            }}>
            <CardContent
              style={{
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <div className="flex flex-col h-full items-center justify-center">
                <img
                  src={profileImg}
                  alt="img"
                  className="flex justify-center object-contain text-center items-center align-middle"
                  style={{ borderRadius: "100px" }}
                />
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
      <div>
        {data && !_.isEmpty(data.childrens_affidatario) && (
          <p className="font-bold text-xl px-6 py-6">I miei figli</p>
        )}
        <div className={`flex flex-row gap-2 px-4 pb-5`}>
          {data &&
            data.childrens_affidatario.map((res, index) => {
              return (
                <div
                  key={index}
                  style={{
                    borderRadius: "20px",
                    backgroundColor: "#F18C08",
                    // width: "70%",
                  }}
                  className="py-1 w-32">
                  <div className="text-white text-center flex flex-row items-center">
                    <img
                      // style={{width: '30px', height: 'auto',}}
                      src={res.gender == "M" ? figlioMaschio : figlioFemmina}
                      alt="img"
                      className="object-cover pl-2"
                    />
                    <div className="flex flex-col justify-start items-start text-sm pl-2">
                      <p>{res.gender == "M" ? "Maschio" : "Femmina"}</p>
                      {/* <p>{res.gender}</p> */}
                      <p>{res.age} anni</p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        <p className="font-bold text-2xl px-6 pt-6">Famiglie abbinate</p>
        {/* <div className="  font-bold text-md px-6 mb-2">
          Famiglia {data.affidanti2.surname}
        </div> */}
        <div className="flex flex-row gap-2 justify-start h-full mx-4">
          {/* {data.affidanti2.map((res, index) => {
            return (
              <div
                style={{
                  borderRadius: "20px",
                  backgroundColor: "#5BBFC1",
                  width: "30%",
                }}
              >
                <div className="text-white text-center h-full items-center flex flex-col justify-center mx-auto p-6">
                  <img
                    src={adultoImg}
                    alt="img"
                    className="object-contain mb-2"
                  />
                  <p>{res.name}</p>
                  <p>{res.surname}</p>
                </div>
              </div>
            );
          })} */}
          {/* <div className="grid grid-cols-2 items-start gap-2">
            {data.childrens_affidatario.map((res, index) => {
              return (
                <div
                  key={index}
                  style={{
                    borderRadius: "20px",
                    backgroundColor: "#F18C08",
                    width: "100%",
                  }}
                >
                  <div className="text-white text-center flex flex-row items-center p-2">
                    <img
                      src={res.gender == "M" ? figlioMaschio : figlioFemmina}
                      alt="img"
                      className="object-cover"
                    />
                    <div className="flex flex-col justify-start items-start text-sm pl-2">
                      <p>{res.gender}</p>
                      <p>{res.age} anni</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div> */}
        </div>
        <div className="font-bold text-md px-6 mb-2 mt-4">
          Famiglia {data.affidato.lenght > 0 ? data.affidato[0].surname : ""}
        </div>
        <div className="flex flex-row gap-2 justify-start h-full mx-4">
          {data.affidato.map((res, index) => {
            return (
              <div
                key={index}
                style={{
                  borderRadius: "20px",
                  backgroundColor: "#5BBFC1",
                  width: "30%",
                }}>
                <div className="text-white text-center h-full items-center flex flex-col justify-center mx-auto p-6">
                  <img
                    src={adultoImg}
                    alt="img"
                    className="object-contain mb-2"
                  />
                  <p>{res.name}</p>
                  <p>{res.surname}</p>
                </div>
              </div>
            );
          })}
          {/* questo */}
          <div className={`grid grid-cols-2 items-start gap-2`}>
            {data.childrens_affidati.map((res, index) => {
              return (
                <div
                  key={index}
                  style={{
                    borderRadius: "20px",
                    backgroundColor: "#F18C08",
                    width: "100%",
                  }}>
                  <div className="text-white text-center flex flex-row items-center p-2">
                    <img
                      // style={{width: '30px', height: 'auto',}}
                      src={res.gender == "M" ? figlioMaschio : figlioFemmina}
                      alt="img"
                      className="object-cover"
                    />
                    <div className="flex flex-col justify-start items-start text-sm pl-1">
                      <p>{res.gender == "M" ? "Maschio" : "Femmina"}</p>
                      <p>{res.age} anni</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div>
        <p className="font-bold text-xl px-6 py-10">
          Attività svolte ({_.size(attivitaSvolte && attivitaSvolte.data)})
        </p>
        <div>
          {/* Banner Success */}
          {openSuccessP && openSuccessP == true && (
            <div
              className="flex flex-row justify-between rounded-3xl shadow-lg py-5 mx-3 my-3"
              style={{
                background: "#FFFFFF",
                background:
                  "radial-gradient(at center, #FFFFFF, rgba(161,218,219,0.3))",
              }}>
              <div
                className="pl-3 "
                style={{ display: "flex", alignItems: "center" }}>
                <button>
                  <img src={success} />
                </button>
              </div>
              <div className="pl-2">
                <p className="font-bold text-xs">
                  La tua foto è stata caricata con successo!
                </p>
                <p className="text-xs pt-1">
                  Grazie per aver caricato la tua foto ed averci lasciato un
                  messaggio.
                </p>
              </div>
              <div
                className="pr-5"
                style={{ display: "flex", alignItems: "center" }}>
                <button onClick={() => setOpenSuccessP(false)}>
                  <CloseIcon />
                </button>
              </div>
            </div>
          )}

          {/* Banner failed */}
          {/* {openSuccessP && openSuccessP == false && (
            <div
              className="flex flex-row justify-between rounded-3xl shadow-lg py-5 mx-3 my-3"
              style={{
                background: "#FFFFFF",
                background:
                  "radial-gradient(at center, #FFFFFF, rgba(241, 140, 8, 0.2))",
              }}
            >
              <div
                className="pl-3 "
                style={{ display: "flex", alignItems: "center" }}
              >
                <button>
                  <img src={failed} />
                </button>
              </div>
              <div className="pl-2">
                <p className="font-bold text-xs">
                  La tua foto è stata caricata con successo!
                </p>
                <p className="text-xs pt-1">
                  Grazie per aver caricato la tua foto ed averci lasciato un
                  messaggio.
                </p>
              </div>
              <div
                className="pr-5"
                style={{ display: "flex", alignItems: "center" }}
              >
                <button>
                  <CloseIcon />
                </button>
              </div>
            </div>
          )} */}

          {_.size(attivitaSvolte.data) > 0 ? (
            attivitaSvolte.data.map((el, index) => {
              return (
                <React.Fragment>
                  <div key={index} className="px-5 pb-5">
                    <CardComponent
                      key={index}
                      setOpenSuccessP={setOpenSuccessP}
                      openSuccessP={openSuccessP}
                      data_svolto_profile={el.ticket_date}
                      typeCard={"profile"}
                      id={el.id}
                      res={el}
                      showDiv={showDiv}
                      img={el.image_url}
                      nameEvent={el.title}
                      typeActivity={el.category.title}
                    />
                  </div>
                </React.Fragment>
              );
            })
          ) : (
            <div className="w-full flex flex-col justify-center items-center">
              <div>
                <CheckCircleIcon
                  className="opacity-25"
                  sx={{ fontSize: "200px" }}
                />
              </div>
              <div className="text-center text-sm pb-16">
                <div className="px-10">
                  <p>Non hai ancora svolto alcuna attività</p>
                  <p>Scegli un evento e inizia subito</p>
                </div>
                <div className="p-5 py-6">
                  <Button variant="contained" onClick={() => navigate("/")}>
                    <p className="text-white normal-case text-md font-bold">
                      Scegli Attività
                    </p>
                  </Button>
                </div>
              </div>
            </div>
          )}

          {/* button pagination */}
          <div className="flex flex-row pb-10 justify-center gap-3">
            {page > 1 && (
              <div className="bg-white p-3 rounded-lg shadow-lg">
                <button
                  onClick={() => {
                    setPage((val) => val - 1);
                    updateAPI(false);
                    mutate("/events-carried-out");
                    window.scrollTo(0, 0);
                  }}>
                  <ArrowBackIosIcon />
                </button>
              </div>
            )}
            {_.size(attivitaSvolte.data) > 5 && (
              <div className="bg-white p-3 rounded-lg shadow-lg">
                <button
                  onClick={() => {
                    setPage(page + 1);
                    mutate("/events-carried-out");
                    updateAPI(true);
                    window.scrollTo(0, 0);
                  }}>
                  <ArrowForwardIosIcon />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </TopBarComponent>
  );
};

const mapState = (state) => ({
  count: state.count,
});
export default connect(mapState)(Profilo);
