import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import bimbi_logo from "../../img/bimbi_logo.png";
import tutorial_1 from "../../img/ico/tutorial_1.png";
import tutorial_2 from "../../img/ico/tutorial_2.png";
import tutorial_3 from "../../img/ico/tutorial_3.png";
import tutorial_4 from "../../img/ico/tutorial_4.png";
import tutorial_5 from "../../img/ico/tutorial_5.png";
import { useNavigate } from "react-router";
import Button from "@mui/material/Button";

const Tutorial = () => {
  const navigate = useNavigate();

  return (
    <Carousel
      showArrows={false} showThumbs={false} showStatus={false}>
      <div className="h-screen">
        <div className="absolute z-10 top-10 end-0">
          <Button className="" onClick={() => navigate("/altro")}>
            skip
          </Button>
        </div>

        <div className="flex flex-col h-full items-center">
          <img src={bimbi_logo} style={{ width: 280 }} alt="logo bimbi educati" />
          <div className="">
            <p className="font-bold text-xl text-center">
              Per la login contatta la città di riferimento.
            </p>
          </div>
          {/*<div className="text-center text-gray-500 text-sm" >*/}
          {/*  <div className="py-1">*/}
          {/*    <p className="font-bold">Napoli</p>*/}
          {/*    <p>affidoculturale.napoli@gmail.com</p>*/}
          {/*    /!* <p>tel <a href="tel:3278559833">327 855 98 33</a></p> *!/*/}
          {/*  </div>*/}
          {/*  <div className="py-1">*/}
          {/*    <p className="font-bold">Roma</p>*/}
          {/*    <p>affidoculturale.roma@gmail.com</p>*/}
          {/*    /!* <p>tel <a href="tel:3519882078">351 988 20 78</a></p> *!/*/}
          {/*  </div>*/}
          {/*  <div className="py-1">*/}
          {/*    <p className="font-bold">Modena</p>*/}
          {/*    <p>affidoculturale.modena@gmail.com</p>*/}
          {/*    /!* <p>tel <a href="tel:0598677814">059 867 78 14</a></p> *!/*/}
          {/*  </div>*/}
          {/*  <div className="py-1">*/}
          {/*    <p className="font-bold">Milano</p>*/}
          {/*    <p>affidoculturale.milano@gmail.com</p>*/}
          {/*  </div>*/}
          {/*  <div className="py-1">*/}
          {/*    <p className="font-bold">Bari</p>*/}
          {/*    <p>affidoculturale.bari@gmail.com</p>*/}
          {/*  </div>*/}
          {/*  <div className="py-1">*/}
          {/*    <p className="font-bold">Venezia</p>*/}
          {/*    <p>affidoculturale.venezia@gmail.com</p>*/}
          {/*  </div>*/}
          {/*  <div className="py-1">*/}
          {/*    <p className="font-bold">Abruzzo</p>*/}
          {/*    <p>affidoculturale.abruzzo@gmail.com</p>*/}
          {/*  </div>*/}
          {/*  <div className="py-1">*/}
          {/*    <p className="font-bold">Sardegna</p>*/}
          {/*    <p>marta.chessa@pantareisardegna.it</p>*/}
          {/*  </div>*/}
          {/*  <div className="py-1">*/}
          {/*    <p className="font-bold">Toscana</p>*/}
          {/*    <p>sansone@toscanaspettacolo.it</p>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>

      <div className="h-screen">
        <div className="flex flex-col justify-between h-full items-center py-20 pb-60 relative">
          <div className="absolute z-10 top-10 end-0">
            <Button className="" onClick={() => navigate("/altro")}>
              skip
            </Button>
          </div>
          <img src={tutorial_1} style={{ width: 300 }} />
          <p className="px-10 font-medium text-lg">
            Cerca l'evento per bambini più adatto alle vostre esigenze
          </p>
        </div>
      </div>
      <div className="h-screen">
        <div className="flex flex-col justify-between h-full items-center py-20 pb-60 relative">
          <div className="absolute z-10 top-10 end-0">
            <Button className="" onClick={() => navigate("/altro")}>
              skip
            </Button>
          </div>
          <img src={tutorial_2} style={{ width: 300 }} />
          <p className="px-10 font-medium text-lg">
            Non perderlo di vista! Salvalo nei tuoi preferiti
          </p>
        </div>
      </div>

      <div className="h-screen">
        <div className="flex flex-col justify-between h-full items-center py-20 pb-60 relative">
          <div className="absolute z-10 top-10 end-0">
            <Button className="" onClick={() => navigate("/altro")}>
              skip
            </Button>
          </div>
          <img src={tutorial_3} style={{ width: 300 }} />
          <p className="px-10 font-medium text-lg">
            Assicurati di avere abbastanza eDucati per l'evento selezionato
            <br />(riservato agli utenti di affido culturale)
          </p>
        </div>
      </div>

      <div className="h-screen">
        <div className="flex flex-col justify-between h-full items-center py-20 pb-60 relative">
          <div className="absolute z-10 top-10 end-0">
            <Button className="" onClick={() => navigate("/altro")}>
              skip
            </Button>
          </div>
          <img src={tutorial_4} style={{ width: 300 }} />
          <p className="px-10 font-medium text-lg">
            Quando sarete all'evento genera il QR Code selezionando la data
            dell'evento per partecipare
          </p>
        </div>
      </div>

      <div className="h-screen">
        <div className="flex flex-col justify-between h-full items-center py-20 pb-60 relative">
          <div className="absolute z-10 top-10 end-0">
            <Button className="" onClick={() => navigate("/altro")}>
              skip
            </Button>
          </div>
          <img src={tutorial_5} style={{ width: 300 }} />
          <p className="px-10 font-medium text-lg">
            Negli eventi a cui si è partecipato vi è la possibilità di mandare una foto, un commento e il feedback tramite la sezione profilo
          </p>
        </div>
      </div>
    </Carousel>
  );
};
export default Tutorial;
