import * as React from "react";
import * as Yup from "yup";
import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import TextFieldCustom from "../components/TextFieldCustom";
import TopBarComponent from "../components/TopBarComponent";
import { useFormik } from "formik";
import { useState } from "react";

import api from "./../conf/api";
import useSWR from "swr";
import axios from "axios";

import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const LogIn = (props) => {
  // const { data, error, mutate } = useSWR("/login", (url) =>
  //   api.post(url, {
  //       email:"sara.molgora@live.it",
  //       password:"123456"
  //   }).then((res) => {
  //       props.dispatch.count.addToken(res.data.access_token)
  //     return res.data;
  //   })
  // );

  const navigate = useNavigate();

  // const apiPost = (values) => {
  //   axios
  //     .post("https://staging.affidoculturale.org/api/v2/login", {
  //       values,
  //       headers,
  //     })
  //     .then((response) => {
  //       // console.log(response);
  //       // console.log(headers)
  //     })
  //     .catch((error) => console.log("Errore"));
  // };

  const LogInSchema = Yup.object().shape({
    email: Yup.string("Inserisci la tua email")
      .email("Mail non valida")
      .required("Mail obbligatoria"),

    password: Yup.string("Inserisci la password")
      .min(3, "Password troppo corta!")
      .max(12, "Password troppo lunga!")
      .required("Password obbligatoria"),
  });

  // dati Log in
  // rosaria293@libero.it
  // 123456
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LogInSchema,
    onSubmit: (values) => {
      api
        .post("/login", values)
        .then((res) => {
          props.dispatch.count.addToken(res.data.access_token);
          navigate("/profilo");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  return (
    <TopBarComponent>
      <div className="pt-24 lg:pt-40 h-full w-full flex flex-row justify-center items-center">
        <form
          component="form"
          noValidate
          autoComplete="off"
          className="bg-white shadow-md rounded px-8 pt-6 pb-4 mb-4 w-80"
          onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-1 text-center">
            <div className="p-2 m-2 flex items-center justify-center">
              <label className="text-4xl flex items-center text-center">
                Accedi
              </label>
            </div>
            <div className="m-2">
              <TextFieldCustom
                required
                id="email"
                label="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                className="w-60"
              />
            </div>

            <div className="m-2">
              <TextFieldCustom
                required
                id="password"
                label="password"
                type="password"
                name="password"
                autoComplete="current-password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                className="w-60"
              />
            </div>
            <div className="flex justify-center p-5">
              <Button variant="contained" type="submit" sx={{ color: "white" }}>
                Accedi
              </Button>
            </div>
            {/* <div className="text-center text-xs">
              <p className="">Non sei registrato?</p>
              <a
                className="hover:underline hover:text-sky-600 cursor-pointer"
                //   onClick={() => history.push("/sign-up")}
              >
                clicca qui
              </a>
            </div> */}
          </div>
        </form>
      </div>
    </TopBarComponent>
  );
};
const mapState = (state) => ({
  count: state.count,
});
export default connect(mapState)(LogIn);
