import * as React from "react";
import TopBarComponent from "../components/TopBarComponent";
import CardCategory from "../components/CardCategory";

const Category = () => {
  return (
    <TopBarComponent>
      <div className="pt-14">
        {[1, 2, 3, 4, 5].map((el) => {
          return (
            <div className="px-5 pb-5">
              <CardCategory />
            </div>
          );
        })}
      </div>
    </TopBarComponent>
  );
};
export default Category;
