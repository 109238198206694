import { Paper } from "@mui/material";
import React from "react";

const BoxCategoria = ({
  title,
  colorText,
  backgroundColor,
  onClickCustom,
  img,
}) => {
  return (
    <Paper
      onClick={onClickCustom}
      sx={{
        borderRadius: "16px",
        height: "120px",
        width: "120px",
        backgroundColor: backgroundColor,
      }}
      elevation={6}>
      <div className="w-full h-full flex flex-col justify-center gap- rounded-xl items-center">
        <img
          src={img}
          alt="img"
          className="object-contain px-2 pt-4 pb-0"
          style={{ width: "80px" }}
        />
        <div
          className={`h-full flex flex-col text-center justify-end mb-4 px-4 text-white font-bold text-white`}
          style={{ fontSize: "13px" }}>
          {title}
        </div>
      </div>
    </Paper>
  );
};

export default BoxCategoria;
