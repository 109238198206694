import React, { useState } from "react";
import TopBarComponent from "../components/TopBarComponent";
import {
  Button,
  DialogActions,
  DialogContent,
  InputAdornment,
  TextField,
} from "@mui/material";
import BoxCategoria from "../components/BoxCategoria";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PeopleIcon from "@mui/icons-material/People";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NearMeIcon from "@mui/icons-material/NearMe";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import _ from "lodash";
import { useGeolocated } from "react-geolocated";
import Loading from "../components/Loading";

import api from "./../conf/api";
import useSWR, { mutate } from "swr";
import AutocompleteApi from "../components/AutocompleteApi";
import moment from "moment";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
import "dayjs/locale/it";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DateCalendar } from "@mui/x-date-pickers";

const Cerca = (props) => {
  // console.log(moment(props.count.dataCalendar.$d).format("DD-MM-YYYY"));

  console.log(props);
  api.setHeaders({
    Authorization: "Bearer " + props.count.token,
    latitude: props.count.latitude,
    longitude: props.count.longitude,
  });
  const { data: me, error: errorMe } = useSWR("/me", (url) =>
    api.get(url).then((res) => res.data.data.user)
  );
  const { data: age, error: errorAge } = useSWR("/age_of_references", (url) =>
    api.get(url).then((res) => res.data.data)
  );
  const { data: category, error: errorCategory } = useSWR(
    "/category-index",
    (url) => api.get(url).then((res) => res.data.data)
  );

  const { data: location, error: errorLocatio } = useSWR("/location", (url) =>
    api.post(url).then((res) => res.data.data)
  );

  const [allEvent, setAllEvent] = useState([]);
  const [allEnti, setAllEnti] = useState([]);
  const [cercaCatalogo, setCercaCatalogo] = useState();
  const [cercaCitta, setCercaCitta] = useState();

  const [dataCalendar, setDataCalendar] = useState(null);
  const [ente, setEnte] = useState();
  const [eta, setEta] = useState();

  const [categoryResult, setCategoryResult] = useState();
  const [page, setPage] = useState(1);

  // Localization Modal
  const [openLocalization, setOpenLocalization] = useState(false);
  const [eventResult, setEventResult] = useState([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();
  const SearchEvent = (val, name) => {
    navigate("/lista-eventi", {
      state: {
        data:
          name === "data"
            ? moment(val && val["$d"]).format("YYYY-MM-DD")
            : dataCalendar
            ? moment(dataCalendar && dataCalendar["$d"]).format("YYYY-MM-DD")
            : null,
        range_eta: name === "range_eta" ? val : eta ? eta : null,
        category_id:
          name === "category_id" ? val : categoryResult ? categoryResult : null,
        search_text: cercaCatalogo,
        search_store: null,
      },
    });
  };

  const handleClickLocalization = () => {
    setOpenLocalization(true);
  };

  const handleCloseLocalization = () => {
    setOpenLocalization(false);
    SearchEvent();
  };

  // Data Modal
  const [openData, setOpenData] = useState(false);
  const handleClickOpenData = () => {
    setOpenData(true);
  };
  const handleCloseData = () => {
    setOpenData(false);
    SearchEvent();
  };

  // Ente Modal
  const [openEnte, setOpenEnte] = useState(false);
  const handleClickOpenEnte = () => {
    setOpenEnte(true);
  };

  // Età Modal
  const [openAge, setOpenAge] = useState(false);
  const handleClickOpenAge = () => {
    setOpenAge(true);
  };

  const [indexRow, setIndexRow] = useState();

  const [openEventiResult, setOpenEventiResult] = useState(false);
  const [hourClicked, setHourClicked] = useState(false);

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  // if (!me) return <Loading />;

  const moment = require("moment");
  moment.locale("it");

  const today = dayjs();

  const city = location && location.split(" ");

  if (props.count.token && !location) return <Loading />;
  // console.log(DateCalendar);
  return (
    <TopBarComponent>
      <div
        className="pt-14"
        style={{
          backgroundColor: "#A1DADB",
          backgroundImage:
            "linear-gradient(180deg, rgba(161,218,219,1) 0%, rgba(255,255,255,1) 100%)",
        }}>
        <div className="flex flex-row justify-between px-6 py-3">
          <div>
            <p className="font-bold text-2xl">Ricerca</p>
          </div>
          {isGeolocationEnabled && me && (
            <div className="flex text-sm">
              <LocationOnIcon sx={{ fontSize: "20px" }} />
              <p>{me && me.city}</p>
            </div>
          )}
          {!me && location && (
            <div className="flex text-sm">
              <LocationOnIcon sx={{ fontSize: "20px" }} />
              <p>{location && city["5"]}</p>
            </div>
          )}
          <Dialog
            fullScreen={fullScreen}
            open={openLocalization}
            onClose={handleCloseLocalization}
            sx={{
              ".css-1osep3w-MuiDialogContent-root": {
                paddingLeft: 0,
                paddingRight: 0,
              },
              marginTop: "70px",
              ".css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop": {
                backgroundColor: "rgba(0, 0, 0, 0.0)",
              },
            }}>
            <DialogTitle id="responsive-dialog-title">
              {"Use Google's location service?"}
            </DialogTitle>
            <DialogContent
              sx={{
                ".css-diy3lx-MuiButtonBase-root-MuiButton-root": {
                  minWidth: 0,
                },
              }}>
              <div className="z-index z-10 px-5">
                <Button
                  className="shadow-md"
                  sx={{
                    borderRadius: "10px",
                  }}
                  onClick={handleCloseLocalization}>
                  <ArrowBackIcon className="text-black" />
                </Button>

                <div
                  className="shadow-md text-center inline-block align-middle ml-20"
                  style={{
                    width: "100px",
                    height: "45px",
                    borderRadius: "20px",
                  }}>
                  <p className="mt-3 font-bold text-sm">Napoli</p>
                </div>
              </div>

              <div
                style={{
                  // backgroundColor: "red",
                  borderRadius: "30px 30px 0px 0px",
                  boxShadow: "0px -19px 16px 0px rgba(0,0,0,0.1)",
                }}
                className="mt-72">
                <div className="flex justify-around pt-5 pl-5 pr-16">
                  <button className="mr-10" onClick={handleCloseLocalization}>
                    <CloseIcon />
                  </button>
                  <p className="font-bold mr-5">Modifica la posizione</p>
                </div>

                <div className="pt-7 flex justify-center px-5">
                  <TextField
                    sx={{
                      borderRadius: "30px",
                      width: "400px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 20,
                      },
                    }}
                    id="search"
                    type="search"
                    placeholder="Inserisci nome città"
                    className="bg-white shadow-md"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{ color: "black" }} />
                        </InputAdornment>
                      ),
                    }}
                    onClick={(e) => {
                      setCercaCitta(e.target.value);
                    }}
                  />
                </div>
                <div className="flex justify-center mt-5">
                  <NearMeIcon
                    className="text-[#6DC6C8]"
                    style={{ fontSize: "20px" }}
                  />
                  <p className="text-[#6DC6C8] underline text-sm ml-1 mb-10">
                    Utilizza la mia posizione attuale
                  </p>
                </div>
                <div className="flex justify-center">
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#6DC6C8", color: "white" }}
                    // onClick={() => console.log(cercaCitta)}
                  >
                    Cerca
                  </Button>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
        <div className="flex justify-center pt-3">
          <AutocompleteApi
            setAllEvent={setAllEvent}
            allEvent={allEvent}
            endPoint={"/events-title"}
            enti={false}
          />
        </div>
        <div
          className="pt-6 text-sm capitalize flex justify-end"
          style={{ color: "#ff9100" }}>
          <div className="flex mx-auto gap-2 max-w-sm overflow-x-auto py-2 px-2 mb-3 mt-3">
            <Button
              style={{
                color: "black",
                borderRadius: "22px",
                backgroundColor: "#FFFF",
                paddingLeft: "55px",
                paddingRight: "45px",
                width: "200px",
              }}
              className="shadow-md"
              onClick={handleClickOpenData}>
              <CalendarMonthIcon
                sx={{ fontSize: "20px", color: "#84CFD0", margin: "5px" }}
              />
              <span className="normal-case text-[#84CFD0]">Data</span>
              <KeyboardArrowRightIcon className="text-[#84CFD0]" />
            </Button>
            <Button
              style={{
                color: "black",
                borderRadius: "22px",
                backgroundColor: "#FFFF",
                paddingLeft: "55px",
                paddingRight: "45px",
                width: "200px",
              }}
              className="shadow-md"
              onClick={handleClickOpenEnte}>
              <AccountBalanceIcon
                sx={{ fontSize: "20px", color: "#84CFD0", margin: "5px" }}
              />
              <span className="normal-case text-[#84CFD0]">Ente</span>
              <KeyboardArrowRightIcon className="text-[#84CFD0]" />
            </Button>
            <Button
              style={{
                color: "black",
                borderRadius: "22px",
                backgroundColor: "#FFFF",
                paddingLeft: "55px",
                paddingRight: "45px",
                width: "200px",
              }}
              className="shadow-md"
              onClick={handleClickOpenAge}>
              <PeopleIcon
                sx={{ fontSize: "20px", color: "#84CFD0", margin: "5px" }}
              />
              <span className="normal-case text-[#84CFD0]">Età</span>
              <KeyboardArrowRightIcon className="text-[#84CFD0]" />
            </Button>
          </div>
        </div>
        <div className="py-3 flex flex-row gap-3 px-4">
          {props.count.etaTitle && (
            <div className="flex font-md border pl-2 rounded-md border-[#84CFD0] bg-white text-[#84CFD0]">
              <p>{props.count.etaTitle}</p>
              <button
                onClick={() => {
                  props.dispatch.count.setEtaId(null);
                  props.dispatch.count.setEtaTitle(null);
                }}>
                <CloseIcon
                  sx={{ width: "16px", marginLeft: 1, marginRight: 1 }}
                />
              </button>
            </div>
          )}
          {props.count.dataCalendar && (
            <div className="flex font-md border pl-2 rounded-md border-[#84CFD0] bg-white text-[#84CFD0]">
              <p>{moment(props.count.dataCalendar.$d).format("DD-MM-YYYY")}</p>
              <button
                onClick={() => props.dispatch.count.setDataCalendar(null)}>
                <CloseIcon
                  sx={{ width: "16px", marginLeft: 1, marginRight: 1 }}
                />
              </button>
            </div>
          )}
        </div>
        {props.count.ente && (
          <div className="flex font-md border pl-2 flex-row justify-between rounded-md border-[#84CFD0] bg-white text-[#84CFD0] mx-4 mb-4">
            <p>{props.count.ente}</p>
            <button onClick={() => props.dispatch.count.setEnte(null)}>
              <CloseIcon
                sx={{ width: "16px", marginLeft: 1, marginRight: 1 }}
              />
            </button>
          </div>
        )}

        {(props.count.dataCalendar ||
          props.count.etaId ||
          // || categoryResult || cercaCatalogo
          props.count.ente) && (
          <div className="mx-4 mb-6">
            <div
              onClick={() => {
                setDataCalendar(null);
                setEta(null);
                setCategoryResult(null);
                setCercaCatalogo(null);
                setEnte(null);
                props.dispatch.count.setEtaId(null);
                props.dispatch.count.setEtaTitle(null);

                props.dispatch.count.setDataCalendar(null);
                props.dispatch.count.setEnte(null);
              }}
              fullWidth
              style={{
                color: "black",
                // borderRadius: "22px",
                borderColor: "#84CFD0",
                backgroundColor: "#FFFF",
                paddingLeft: "55px",
                paddingRight: "45px",
              }}
              className="shadow-md border border-[#84CFD0] p-1 text-center w-full justify-center rounded-lg">
              <span className="normal-case font-bold text-[#84CFD0] mt-1 text-center">
                Cancella tutti i filtri
              </span>
            </div>
          </div>
        )}
      </div>

      <div>
        <div className="px-8 pt-2 mb-6 flex flex-row h-full itmes-center gap-4">
          <div className="font-bold text-md flex flex-row justify-center">
            <p>Categorie più popolari</p>
          </div>
        </div>
        <div className="flex flex-col w-full px-8 gap-8 mb-12 ">
          <div className="grid grid-cols-2 gap-12 w-full justify-center">
            {category &&
              category.map((res, index) => {
                return (
                  <React.Fragment key={index}>
                    <BoxCategoria
                      onClickCustom={() => {
                        // console.log(res);
                        setCategoryResult(res.id);
                        SearchEvent(res.id, "category_id");
                      }}
                      title={res.title}
                      colorText={res.color_text}
                      backgroundColor={res.color}
                      img={res.image_path}
                    />
                  </React.Fragment>
                );
              })}
          </div>
        </div>
      </div>
      {/* Data Modal */}
      <div>
        <Dialog
          open={openData}
          onClose={() => setOpenData(false)}
          PaperProps={{
            style: {
              borderRadius: 23,
              paddingBottom: 10,
              paddingTop: 10,
              position: "absolute",
              bottom: "10%",
              left: 0,
              right: 0,
              margin: "auto",
              maxHeight: "80vh",
              width: "90%",
              // borderRadius: '20px 20px 0 0',
            },
          }}
          sx={{
            ".MuiDialogContent-root": {
              paddingLeft: 0,
              paddingRight: 0,
              paddingBottom: 0,
            },
            ".css-1cafy48-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition":
              {
                minHeight: 194,
              },
          }}
          fullWidth>
          <DialogTitle className="text-center flex justify-between">
            <p className="font-bold pt-1 pl-14" style={{ fontSize: "16px" }}>
              Seleziona una data
            </p>
            <IconButton onClick={() => setOpenData(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {/* calendario funzioniante in italiano */}
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="it"
              sx={{ position: "relative", zIndex: 1 }}>
              <DateCalendar
                dayOfWeekFormatter={(day, date) => {
                  if (day == "Su") return "Dom";
                  if (day == "Mo") return "Lun";
                  if (day == "Tu") return "Mar";
                  if (day == "We") return "Mer";
                  if (day == "Th") return "Gio";
                  if (day == "Fr") return "Ven";
                  if (day == "Sa") return "Sab";
                }}
                renderInput={(props) => <TextField {...props} />}
                value={dataCalendar}
                onChange={(newValue) => {
                  setDataCalendar(newValue);
                  props.dispatch.count.setDataCalendar(newValue);
                }}
                sx={{
                  "& .MuiPickersCalendarHeader-label": {
                    color: "#6EC5C7",
                  },
                  ".MuiTypography-root": {
                    color: "#F29A26",
                    fontWeight: "bold",
                    fontSize: 13,
                  },
                  "& .MuiPickersDay-root.Mui-selected": {
                    color: "white",
                    backgroundColor: "#F29A26",
                  },
                  "& .MuiPickersDay-root.Mui-selected:hover": {
                    color: "white",
                    backgroundColor: "#F29A26",
                    fontWeight: "bold",
                  },
                  "& .MuiPickersDay-root:not(.Mui-selected)": {
                    border: "0px transparent",
                    fontSize: 14,
                  },
                }}
              />
            </LocalizationProvider>
          </DialogContent>
          <DialogActions className="mr-5">
            <Button
              onClick={() => {
                setOpenData(false);
                SearchEvent(dataCalendar, "data");
              }}
              variant="contained"
              autoFocus>
              <p className="text-white normal-case">Fatto</p>
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {/* Ente Modal */}
      <div>
        <Dialog
          open={openEnte}
          onClose={() => setOpenEnte(false)}
          fullWidth
          PaperProps={{
            style: {
              // borderRadius: 23,
              paddingBottom: 20,
              paddingTop: 10,
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              margin: "auto",
              maxHeight: "80vh",
              width: "100%",
              borderRadius: "23px 23px 0 0",
            },
          }}>
          <DialogTitle>
            <div className="flex">
              <IconButton
                className="flex align-middle"
                style={{ color: "#5BBFC1" }}
                onClick={(e) => setOpenEnte(false)}>
                <CloseIcon className="mt-1" />
                <p className="normal-case mt-1" style={{ fontSize: "16px" }}>
                  chiudi
                </p>
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent className="flex justify-center">
            {/* searchbar  enti */}
            <AutocompleteApi
              setAllEvent={setAllEvent}
              allEvent={allEvent}
              endPoint={"/search-enti"}
              ente={true}
            />
          </DialogContent>
        </Dialog>
      </div>
      {/* Età Modal */}
      <div>
        <Dialog
          open={openAge}
          onClose={() => setOpenAge(false)}
          fullWidth
          PaperProps={{
            style: {
              paddingBottom: 10,
              paddingTop: 10,
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              margin: "auto",
              maxHeight: "80vh",
              width: "100%",
              borderRadius: "23px 23px 0 0",
            },
          }}
          sx={{
            ".MuiDialogContent-root": {
              borderRadius: 23,
              paddingLeft: 0,
              paddingRight: 0,
              paddingBottom: 0,
            },
          }}>
          <DialogTitle>
            <div className="flex">
              <Button
                className="flex align-middle"
                style={{ color: "#5BBFC1" }}
                onClick={() => setOpenAge(false)}>
                <CloseIcon className="mt-1" />
                <p className="normal-case mt-1" style={{ fontSize: "16px" }}>
                  chiudi
                </p>
              </Button>
            </div>
          </DialogTitle>
          <DialogContent className="flex flex-row justify-center gap-2 mb-3">
            {age &&
              age.map((el, index) => {
                // console.log(el);
                return (
                  <React.Fragment key={index}>
                    <div
                      onClick={() => {
                        setIndexRow(index);
                        setEta(el.id);
                        props.dispatch.count.setEtaId(el.id);
                        props.dispatch.count.setEtaTitle(el.value);
                      }}
                      className="px-5 py-2 border mx-1 uppercase text-sm border-[#5BBFC1]"
                      variant="outlined"
                      style={{
                        color: "#5BBFC1",
                        borderColor: "#5BBFC1",
                        borderRadius: "9px",
                        // color: "#5BBFC1",
                        // fontSize: "13px",
                        // paddingLeft: "8px",
                        // paddingRight: "8px",
                        backgroundColor:
                          indexRow === index ? "#5BBFC1" : "white",
                        color: indexRow === index ? "white" : "#5BBFC1",
                        ".css-1xlk46l-MuiButtonBase-root-MuiButton-root:hover":
                          {
                            border: "#5BBFC1",
                          },
                      }}>
                      {el.value}
                    </div>
                  </React.Fragment>
                );
              })}
          </DialogContent>
          <DialogActions
            className="mr-5"
            sx={{
              ".css-16w4spq-MuiButtonBase-root-MuiButton-root:hover": {
                backgroundColor: "#5BBFC1",
              },
            }}>
            <Button
              onClick={() => {
                setOpenAge(false);
                SearchEvent(eta, "range_eta");
              }}
              variant="contained"
              autoFocus
              sx={{
                backgroundColor: "#5BBFC1",
              }}>
              <p className="text-white normal-case">Cerca</p>
            </Button>
          </DialogActions>
        </Dialog>
        {/* MODAL CON RISULTATI EVENTI */}
        {/* <Dialog
          open={openEventiResult}
          onClose={() => setOpenEventiResult(false)}
          fullScreen
          fullWidth>
          <DialogTitle>
            <IconButton
              style={{ color: "#5BBFC1" }}
              onClick={() => setOpenEventiResult(false)}>
              <CloseIcon className="mt-1" />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              backgroundColor: "#A1DADB",
              backgroundImage:
                "linear-gradient(180deg, rgba(161,218,219,1) 0%, rgba(255,255,255,1) 100%)",
            }}>
            <div className="flex flex-col justify-center gap-4 mb-3 pt-4">
              {eventResult.map((el, index) => {
                return (
                  <React.Fragment key={index}>
                    <CardComponent
                      typeCard={"topCard"}
                      id={el.id}
                      res={el}
                      img={el.image_url}
                      nameEvent={el.title}
                      typeActivity={el.category.title}
                    />
                  </React.Fragment>
                );
              })}
            </div>
          </DialogContent>
        </Dialog> */}
      </div>
    </TopBarComponent>
  );
};
const mapState = (state) => ({
  count: state.count,
});
export default connect(mapState)(Cerca);
