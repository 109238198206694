import { CircularProgress } from "@mui/material";

export const Loading = () => {
  return (
    <div className="h-screen w-full flex flex-row justify-center items-center">
      <CircularProgress />
    </div>
  );
};
export default Loading;
