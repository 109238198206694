import * as React from "react";
import { useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import DoneIcon from "@mui/icons-material/Done";
import StarIcon from "@mui/icons-material/Star";
import ForestIcon from "@mui/icons-material/Forest";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";

const CardComponent = ({ typeCard, typeActivity }) => {
  // console.log(typeCard);

  const [colorLabel, setColorLabel] = useState("#9BD2A8");

  return (
    <React.Fragment>
      <div className="shadow-lg" style={{ borderRadius: "25px" }}>
        <Card style={{ borderRadius: "25px", minWidth: "161.5px" }}>
          <CardActionArea>
            <div>
              <CardMedia
                component="img"
                // height="70"
                image="https://picsum.photos/300/200"
                className="h-32"
              />

              <div
                style={{ backgroundColor: `${colorLabel}` }}
                className={`relative bottom-6 w-24 h-6  text-white`}
              >
                <ForestIcon sx={{ width: "15px", paddingBottom: "2px" }} />

                <span
                  style={{ fontSize: "9px" }}
                  className="uppercase text-dark p-1"
                >
                  all'aperto
                </span>
              </div>

              <span
                className="relative bg-white p-1 align-middle pb-2 shadow-lg"
                style={{
                  borderRadius: "10px",
                  left: "290px",
                  bottom: "130px",
                }}
              >
                <StarBorderIcon
                  sx={{ width: "20px", height: "20px" }}
                  className="text-gray-400"
                />
              </span>
            </div>
            <div className="flex px-5">
              <p>Alla scoperta dei Quartieri Spagnoli</p>
            </div>
          </CardActionArea>
          <CardActions className="flex justify-end">
            <Button size="small">
              <p
                gutterbottom={1}
                variant="string"
                component="div"
                className="underline text-black font-bold text-sm normal-case pb-2 pr-2"
              >
                Scopri di più
              </p>

              <ArrowForwardIosIcon className="text-sm text-black" />
            </Button>
          </CardActions>
        </Card>
      </div>
    </React.Fragment>
  );
};
export default CardComponent;
