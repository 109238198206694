import { createTheme } from "@mui/material/styles";
import { itIT as coreitIT } from "@mui/material/locale";
import { itIT } from "@mui/x-date-pickers/locales";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#F18C09",
    },
    secondary: {
      main: "#a7a492",
    },
  },
  itIT, // x-date-pickers translations
  coreitIT, // core translations
  greenCustom: "#19C35D",
  // typography: {
  //   fontFamily: "'Titillium Web', sans-serif",
  //   fontWeightRegular: 400,
  //   fontWeightBold: 700,
  // },
});
