import { create } from "apisauce";

const api = create({
  baseURL: "https://api.affidoculturale.org/api/v2",
  // baseURL: "http://127.0.0.1:8000/api/v2",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
export default api;
