import * as React from "react";
import StarsIcon from "@mui/icons-material/Stars";
import TopBarComponent from "../components/TopBarComponent";
import CardComponent from "../components/CardComponent";
import api from "./../conf/api";
import useSWR from "swr";
import ErrorsComponent from "../components/ErrorComponent";
import Loading from "../components/Loading";
import { connect } from "react-redux";
import { mutate } from "swr";
import nessun_pref from "../img/ico/nessun_pref.png";
import _ from "lodash";

const Preferiti = (props) => {
  api.setHeaders({
    Authorization: "Bearer " + props.count.token,
    latitude: props.count.latitude,
    longitude: props.count.longitude,
  });

  const { data, error } = useSWR(
    props.count.token ? "/bookmarked" : null,
    (url) => api.get(url).then((res) => res.data.data)
  );

  if (error) return <ErrorsComponent />;
  if (props.count.token && !data) return <Loading />;

  return (
    <TopBarComponent>
      {data && data.length <= 0 ? (
        <div className="h-screen w-full flex flex-col justify-center items-center">
          <div>
            <img src={nessun_pref} alt="" />
          </div>

          <div className="text-center">
            <div className="font-bold">
              <p>Nessun preferito!</p>
            </div>
            <div className="px-20">
              <p>Aggiungi gli eventi alla tua lista dei preferiti</p>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "#A1DADB",
            backgroundImage:
              "linear-gradient(180deg, rgba(161,218,219,1) 0%, rgba(255,255,255,1) 100%)",
          }}
          className="py-5">
          <div className="font-bold text-2xl pb-4 pt-8 px-5">
            I miei preferiti ({_.size(data)})
          </div>

          {data &&
            data.map((el, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="px-5 py-2">
                    <CardComponent
                      mutate={mutate}
                      typeCard={"topCard"}
                      id={el.id}
                      pref={true}
                      res={el}
                      img={el.image_url}
                      nameEvent={el.title}
                      typeActivity={"el.cat_name"}
                    />
                  </div>
                </React.Fragment>
              );
            })}
        </div>
      )}
    </TopBarComponent>
  );
};

const mapState = (state) => ({
  count: state.count,
});
export default connect(mapState)(Preferiti);
