import * as React from "react";
import { TextField } from "@mui/material";

const TextFieldCustom = ({title, ...rest}) => {
  return (
  <div>
    <div className="text-md m-2">{title}</div>
    <TextField {...rest} />
  </div>
  );
};
export default TextFieldCustom;